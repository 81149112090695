<template>
  <div id="content">
    <h3>{{ tournament_name }}</h3>
    <div id="loader" v-show="loading">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <div v-show="isEditor && !games.length && !loading" id="create-chart-div">
      <label>Тип сетки</label>
      <select class="chart-type-select" v-model="newChart.chart_type" @change="chartTypeChange()">
        <option value="classic">Классическая</option>
        <option value="round">Круговая</option>
        <option value="group">Групповая</option>
        <option value="seed">Сеянная</option>
      </select>
      <label v-if="newChart.chart_type=='classic' || newChart.chart_type=='seed'">Количество участников в первом раунде</label>
      <input v-if="newChart.chart_type=='classic' || newChart.chart_type=='seed'" type="number" v-model="newChart.num_players" v-on:keyup.enter="createChart">
      <label v-if="newChart.chart_type=='classic' || newChart.chart_type=='seed'">Количество участников в олимпийке</label>
      <input v-if="newChart.chart_type=='classic' || newChart.chart_type=='seed'" type="number" v-model="newChart.num_final_players" v-on:keyup.enter="createChart">
      <label v-if="newChart.chart_type=='group'">Количество групп</label>
      <input v-if="newChart.chart_type=='group'" type="number" v-model="newChart.num_groups" v-on:keyup.enter="createChart">
      <label v-if="newChart.chart_type=='group'">Сколько выходит из группы</label>
      <input v-if="newChart.chart_type=='group'" type="number" v-model="newChart.num_group_winners" v-on:keyup.enter="createChart">
      <div v-if="newChart.chart_type=='classic' || newChart.chart_type=='group' || newChart.chart_type=='seed'">
        <input type="checkbox" v-model="newChart.third_place_game" :disabled="newChart.num_final_players == 2">
        <span>Игра за третье место</span>
      </div>
      <div v-if="newChart.chart_type=='classic' || newChart.chart_type=='group'">
        <input type="checkbox" v-model="newChart.lot_by_region" @change="() => {if (newChart.lot_by_region) newChart.lot_by_rating=false}" >
        <span>Рассеять по региону</span>
      </div>
      <div v-if="newChart.chart_type!='seed' && rating_id">
        <input type="checkbox" v-model="newChart.lot_by_rating" @change="() => {if (newChart.lot_by_rating) newChart.lot_by_region=false}">
        <span>Рассеять по рейтингу</span>
      </div>
      <div class="seed-rounds" v-if="newChart.chart_type=='seed'">
        <a class='add-button' title="Добавить сеянный раунд" @click="addSeedRound()"><i class="fa-solid fa-circle-plus"></i></a>
        <div v-for="(round, index) in newChart.seed_rounds" :key="index" class="seed-round-container">
          <div class="seed-round-header">
            <span v-if="round.top">Топ</span>
            <select v-model="round.count" @change="seedRoundCountChange(round, index)" class="seed-round-count">
              <option v-for="option in round.count_options" :key="option.value" :value="option.value">{{option.title}}</option>
            </select>
            <a class='delete-button' v-if="!round.no_delete && index == newChart.seed_rounds.length - 1" @click="deleteSeedRound(index)"><i class="fa-solid fa-trash-can"></i></a>
          </div>
          <div class="participants-list" v-if="round.participants.length">
            <div class="participant-cell" v-for="(participant, index) in round.participants" :key="participant.id" :class="{'last': index == round.participants.length - 1}">
              {{participant.name}}
            </div>
          </div>
        </div>
      </div>
      <button :disabled="creatingChart" @click="createChart">
        <span v-show="!creatingChart">Создать сетку</span>
        <i class="fas fa-spinner fa-pulse" v-show="creatingChart"></i>
      </button>
    </div>
    <div class="actions" v-if="isEditor && games.length && !loading">
      <div class="toggle-with-label" v-if="chart_type != 'round'">
        <span>Расписание</span>
        <v-toggle v-model="scheduling" :classes="{container: 'outline-none'}" on-label="on" off-label="off" @change="chartToggle('scheduling')"/>
      </div>
      <div class="toggle-with-label" v-if="chart_type != 'round'">
        <span>До скольки побед</span>
        <v-toggle v-model="till_setup" :classes="{container: 'outline-none'}" on-label="on" off-label="off" @change="chartToggle('till_setup')"/>
      </div>
      <div class="gear_setting_container" v-click-outside="() => settings_dropdown_expanded = false">
        <a class="gear_settings_expand_button" :class="{'expanded': settings_dropdown_expanded}" v-show="!(loading || scheduling || till_setup)"
        @mouseover="settings_dropdown_hover = true" @mouseout="settings_dropdown_hover = false"
        @click="settings_dropdown_expanded=!settings_dropdown_expanded"> 
          <i class="fa-solid fa-gear" :class="{'fa-spin-pulse': settings_dropdown_hover}"></i>
        </a>
        <div class="gear_settings_expand" v-show="settings_dropdown_expanded">
          <button @click="changeTournamentStatus('finished')" v-if="tournament_status != 'finished'">
            <span v-show="!changing_status">Завершить турнир</span>
            <i class="fas fa-spinner fa-pulse" v-show="changing_status"></i>        </button>
          <button @click="changeTournamentStatus('pending')" v-else>
            <span v-show="!changing_status">Продолжить турнир</span>
            <i class="fas fa-spinner fa-pulse" v-show="changing_status"></i>
          </button>
          <button @click="editChart(false)" v-if="(chart_type == 'classic' || chart_type == 'seed') && gamesByStage['P3']">
            <span v-show="!editingChart">Удалить игру за 3 место</span>
            <i class="fas fa-spinner fa-pulse" v-show="editingChart"></i>
          </button>
          <button @click="editChart(true)" v-if="(chart_type == 'classic' || chart_type == 'seed') && !gamesByStage['P3']">
            <span v-show="!editingChart">Добавить игру за 3 место</span>
            <i class="fas fa-spinner fa-pulse" v-show="editingChart"></i>
          </button>
          <button @click="deleteChart">
            <span v-show="!deletingChart">Удалить сетку</span>
            <i class="fas fa-spinner fa-pulse" v-show="deletingChart"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="chart_type != 'group' && games.length &&  chart_type != 'round'" class="chart-search-div" v-show="!(loading || scheduling || till_setup)" v-click-outside="() => search.list_show = false" :class="{'mobile': isMobile, 'expanded': search.list_show}">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input v-show="!search.player_name" :value='search.str' @input='evt=>search.str=evt.target.value' @focusin="search.list_show = true"/>
      <label v-show="search.player_name">{{search.player_name}}</label>
      <button v-show="search.player_name" @click="search.player_name=null; search.player_id=null; $router.replace({ query: {}})">X</button>
      <Transition name="expand">
        <div class="players-list" v-show="search.list_show && searchPlayersList.length">
          <a v-for="player in searchPlayersList" :key="player.id" @click="search.list_show = false; search.player_name=player.name; search.player_id=player.id; search.str = ''; $router.replace({query: { player: search.player_id }})">
            {{player.name}}
          </a>
        </div>
      </Transition>
    </div>
    <span class="error" v-show="error">{{error}}</span>
    <div class='chart-container-with-admin' v-if="!search.player_name">
      <div v-if="chart_type == 'classic' || chart_type == 'seed'" v-show="games.length && !loading && !scheduling && !till_setup" id="chart-container">
        <div v-for="game in games" :id="game.game_number + '-game'" :key="game.id" class="game-table" :style="{top: game.location_top + 'px',left: game.location_left + 'px'}">
          <div class="game-header">
            <div class="youtube-link" v-if="game.youtube_link">
              <a :href="game.youtube_link" target="_blank">
                <i class="fa-brands fa-youtube"></i>
              </a>
            </div>
            <div class="game-number" @mouseover="gameNumberMouseover(game)" @mouseout="gameNumberMouseout(game)" @click="gameNumberClick(game)">{{ game.game_number }}</div>
            <div class="table-number" v-show="game.table_name">{{ `Стол #${game.table_name}` }}</div>
            <div class="game-time">{{ game.time }}</div>
          </div>
          <div class="game-table-line" @click="isEditor && selectGame(game)">
            <div class="game-table-player-lot">{{ game.player1_lot }}</div>
            <div class="game-table-name-cell" :class="{ 'walk-over': game.player1_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready' }">
              <div class="flag-container" v-if="game.player1_flag"><country-flag :country="game.player1_flag" :title="game.player1_country" size='small'/></div>
              <label>{{ game.player1_name }}</label>
            </div>
            <div class="game-table-score-cell" :class="game.status">
              <span>{{ game.player1_score }}</span>
            </div>
            <div class="game-table-handicap" v-if="game.player1_handicap">{{game.player1_handicap}}</div>
          </div>
          <div class="line-connector" @click="isEditor && selectGame(game)">
            <div style="width:21px;"></div>
            <div style="width:124px;" :id="game.id"></div>
          </div>
          <div class="game-table-line" @click="isEditor && selectGame(game)">
            <div class="game-table-player-lot">{{ game.player2_lot }}</div>
            <div class="game-table-name-cell" :class="{ 'walk-over': game.player2_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready'}">
              <div class="flag-container" v-if="game.player2_flag"><country-flag :country="game.player2_flag" :title="game.player2_country" size='small'/></div>
              <label>{{ game.player2_name }}</label>
            </div>
            <div class="game-table-score-cell" :class="game.status">
              <span>{{ game.player2_score }}</span>
            </div>
            <div class="game-table-handicap" v-if="game.player2_handicap">{{game.player2_handicap}}</div>
          </div>
          <div class="game-description">
            <div v-show="game.description" v-if="isEditor && game.description && (game.description.startsWith('winner') || game.description.startsWith('loser')) && game.stage != 'SF'">
              <label v-if="game.description.startsWith('winner')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">побед. на </label>
              <select v-if="game.description.startsWith('winner')" v-model="game.winner_to"
                @focus="old_next_game = game.winner_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
              </select>
              <label v-if="game.description.startsWith('loser')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">проигр. на </label>
              <select v-if="game.description.startsWith('loser')" v-model="game.loser_to"
                @focus="old_next_game = game.loser_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
              </select>
            </div>
            <label v-else @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">{{ game.description ? game.description.replace('loser to', 'проигравший на').replace('winner to', 'победитель на') : '' }}</label>
          </div>
        </div>
        <svg v-for="(svg, index) in svgs"  :key="index"
          :style="{ left: svg.left + 'px', top: svg.top + 'px' }"
          :width="svg.width" :height="svg.height">
          <line v-for="(line, line_index) in svg.lines" :key="line_index"
            :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" style="stroke:#b1b0b0;stroke-width:1" />
        </svg>
      </div>
      <div v-if="chart_type == 'group'" v-show="games.length && !loading && !scheduling && !till_setup" id="group-chart-container">
        <h4 @click="() => {this.group_games_visible = !this.group_games_visible}">
          <span class="unselectable">Групповой этап</span>
          <i class="fa-solid fa-angle-down expander" v-show="group_games_visible"></i>
          <i class="fa-solid fa-angle-right expander" v-show="!group_games_visible"></i>
        </h4>
        <div v-show="group_games_visible" id="group-container">
          <div class="group-games-container" v-for="group in groups" :key="group.name">
            <div class="group-games-container-header">
              <span>{{`Группа ${String.fromCharCode(group.name + 64)}`}}</span> 
            </div>
            <div class="group-games-container-content">
              <table class="group-participants">
                <thead>
                  <th>Жребий</th>
                  <th>Имя</th>
                  <th>Выигр./Проигр.</th>
                  <th>Очки</th>
                </thead>
                <tbody>
                  <tr v-for="participant in group.participants" :key="participant.id">
                    <td>{{participant.lot}}</td>
                    <td>{{participant.name}}</td>
                    <td>{{ `${participant.wins}/${participant.fails}` }}</td>
                    <td>{{participant.group_points}}</td>
                  </tr>
                </tbody>
              </table>
              <table class="group-chart">
                <thead>
                  <th>#</th>
                  <th>Игрок</th>
                  <th v-for="n in group.participants.length" :key="n">{{n}}</th>
                </thead>
                <tbody>
                  <tr v-for="(participant, index) in group.participants" :key="participant.id">
                    <td>{{index + 1}}</td>
                    <td>{{participant.name}}</td>
                    <td v-for="opponent in group.participants" :key="opponent.id" class="score-cell" :class="{ [group.scores[participant.id][opponent.id].status]: true }"
                      @click="isEditor && opponent.id != participant.id && selectGame(games_by_id[group.scores[participant.id][opponent.id].game][0])">
                      {{ opponent.id != participant.id ? group.scores[participant.id][opponent.id].score : "X"}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="group-games">
                <div v-for="game in group.games" :id="game.game_number + '-game'" :key="game.id" class="game-table">
                  <div class="game-header">
                    <div class="youtube-link" v-if="game.youtube_link">
                      <a :href="game.youtube_link" target="_blank">
                        <i class="fa-brands fa-youtube"></i>
                      </a>
                    </div>
                    <div class="table-number" v-show="game.table_name">{{ `Стол #${game.table_name}` }}</div>
                    <div class="game-time">{{ game.time }}</div>
                  </div>
                  <div class="game-table-line" @click="isEditor && selectGame(game)">
                    <div class="game-table-player-lot">{{ game.player1_lot }}</div>
                    <div class="game-table-name-cell" :class="{ 'walk-over': game.player1_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready' }">
                      <div class="flag-container" v-if="game.player1_flag"><country-flag :country="game.player1_flag" :title="game.player1_country" size='small'/></div>
                      <label>{{ game.player1_name }}</label>
                    </div>
                    <div class="game-table-score-cell" :class="game.status">
                      <span>{{ game.player1_score }}</span>
                    </div>
                    <div class="game-table-handicap" v-if="game.player1_handicap">{{game.player1_handicap}}</div>
                  </div>
                  <div class="line-connector" @click="isEditor && selectGame(game)">
                    <div style="width:21px;"></div>
                    <div style="width:124px;" :id="game.id"></div>
                  </div>
                  <div class="game-table-line" @click="isEditor && selectGame(game)">
                    <div class="game-table-player-lot">{{ game.player2_lot }}</div>
                    <div class="game-table-name-cell" :class="{ 'walk-over': game.player2_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready'}">
                      <div class="flag-container" v-if="game.player2_flag"><country-flag :country="game.player2_flag" :title="game.player2_country" size='small'/></div>
                      <label>{{ game.player2_name }}</label>
                    </div>
                    <div class="game-table-score-cell" :class="game.status">
                      <span>{{ game.player2_score }}</span>
                    </div>
                    <div class="game-table-handicap" v-if="game.player2_handicap">{{game.player2_handicap}}</div>
                  </div>
                  <div class="game-description">
                    <div v-show="game.description" v-if="isEditor && game.description && (game.description.startsWith('winner') || game.description.startsWith('loser')) && game.stage != 'SF'">
                      <label v-if="game.description.startsWith('winner')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">winner to </label>
                      <select v-if="game.description.startsWith('winner')" v-model="game.winner_to"
                        @focus="old_next_game = game.winner_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                        <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
                      </select>
                      <label v-if="game.description.startsWith('loser')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">loser to </label>
                      <select v-if="game.description.startsWith('loser')" v-model="game.loser_to"
                        @focus="old_next_game = game.loser_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                        <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
                      </select>
                    </div>
                    <label v-else @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">{{ game.description }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 @click="() => {this.olympic_games_visible = !this.olympic_games_visible}">
          <span class="unselectable">Олимпийка</span>
          <i class="fa-solid fa-angle-down expander" v-show="olympic_games_visible"></i>
          <i class="fa-solid fa-angle-right expander" v-show="!olympic_games_visible"></i>
        </h4>
        <div id="chart-container" v-show="olympic_games_visible">
          <div v-for="game in games" :id="game.game_number + '-game'" :key="game.id" class="game-table" :style="{top: game.location_top + 'px',left: game.location_left + 'px'}">
            <div class="game-header">
              <div class="youtube-link" v-if="game.youtube_link">
                <a :href="game.youtube_link" target="_blank">
                  <i class="fa-brands fa-youtube"></i>
                </a>
              </div>
              <div class="game-number" @mouseover="gameNumberMouseover(game)" @mouseout="gameNumberMouseout(game)" @click="gameNumberClick(game)">{{ game.game_number }}</div>
              <div class="table-number" v-show="game.table_name">{{ `Стол #${game.table_name}` }}</div>
              <div class="game-time">{{ game.time }}</div>
            </div>
            <div class="game-table-line" @click="isEditor && selectGame(game)">
              <div class="game-table-player-lot">{{ convertOlympicLot(game.player1_lot) }}</div>
              <div class="game-table-name-cell" :class="{ 'walk-over': game.player1_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready' }">
                <div class="flag-container" v-if="game.player1_flag"><country-flag :country="game.player1_flag" :title="game.player1_country" size='small'/></div>
                <label>{{ game.player1_name }}</label>
              </div>
              <div class="game-table-score-cell" :class="game.status">
                <span>{{ game.player1_score }}</span>
              </div>
              <div class="game-table-handicap" v-if="game.player1_handicap">{{game.player1_handicap}}</div>
            </div>
            <div class="line-connector" @click="isEditor && selectGame(game)">
              <div style="width:21px;"></div>
              <div style="width:124px;" :id="game.id"></div>
            </div>
            <div class="game-table-line" @click="isEditor && selectGame(game)">
              <div class="game-table-player-lot">{{ convertOlympicLot(game.player2_lot) }}</div>
              <div class="game-table-name-cell" :class="{ 'walk-over': game.player2_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready'}">
                <div class="flag-container" v-if="game.player2_flag"><country-flag :country="game.player2_flag" :title="game.player2_country" size='small'/></div>
                <label>{{ game.player2_name }}</label>
              </div>
              <div class="game-table-score-cell" :class="game.status">
                <span>{{ game.player2_score }}</span>
              </div>
              <div class="game-table-handicap" v-if="game.player2_handicap">{{game.player2_handicap}}</div>
            </div>
            <div class="game-description">
              <div v-show="game.description" v-if="isEditor && game.description && (game.description.startsWith('winner') || game.description.startsWith('loser')) && game.stage != 'SF'">
                <label v-if="game.description.startsWith('winner')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">побед. на </label>
                <select v-if="game.description.startsWith('winner')" v-model="game.winner_to"
                  @focus="old_next_game = game.winner_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                  <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
                </select>
                <label v-if="game.description.startsWith('loser')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">проигр. на </label>
                <select v-if="game.description.startsWith('loser')" v-model="game.loser_to"
                  @focus="old_next_game = game.loser_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                  <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
                </select>
              </div>
              <label v-else @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">{{ game.description ? game.description.replace('loser to', 'проигравший на').replace('winner to', 'победитель на') : '' }}</label>
            </div>
          </div>
          <svg v-for="(svg, index) in svgs"  :key="index"
            :style="{ left: svg.left + 'px', top: svg.top + 'px' }"
            :width="svg.width" :height="svg.height">
            <line v-for="(line, line_index) in svg.lines" :key="line_index"
              :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" style="stroke:#b1b0b0;stroke-width:1" />
          </svg>
        </div>
      </div>
      <div v-if="chart_type == 'round'" v-show="games.length && !loading && !scheduling && !till_setup" id="round-chart-container">
        <div id="round-container">
          <table class="group-chart">
            <thead>
              <th>#</th>
              <th>Игрок</th>
              <th v-for="n in groups[0].participants.length" :key="n">{{n}}</th>
              <th>Место</th>
              <th>Очки</th>
              <th>Выигр/Проигр</th>
            </thead>
            <tbody>
              <tr v-for="(participant, index) in groups[0].participants" :key="participant.id">
                <td>{{index + 1}}</td>
                <td>{{participant.name}}</td>
                <td v-for="opponent in groups[0].participants" :key="opponent.id" class="score-cell" :class="{ [groups[0].scores[participant.id][opponent.id].status]: true }"
                @click="isEditor && opponent.id != participant.id && selectGame(games_by_id[groups[0].scores[participant.id][opponent.id].game][0])">
                  {{ opponent.id != participant.id ? groups[0].scores[participant.id][opponent.id].score : "X"}}
                </td>
                <td>{{participant.group_place}}</td>
                <td>{{participant.group_points}}</td>
                <td>{{`${participant.wins}/${participant.fails}`}}</td>
              </tr>
            </tbody>
          </table>
          <div class="group-games">
            <div v-for="game in groups[0].games" :id="game.game_number + '-game'" :key="game.id" class="game-table">
              <div class="game-header">
                <div class="youtube-link" v-if="game.youtube_link">
                  <a :href="game.youtube_link" target="_blank">
                    <i class="fa-brands fa-youtube"></i>
                  </a>
                </div>
                <div class="table-number" v-show="game.table_name">{{ `Стол #${game.table_name}` }}</div>
                <div class="game-time">{{ game.time }}</div>
              </div>
              <div class="game-table-line" @click="isEditor && selectGame(game)">
                <div class="game-table-player-lot">{{ game.player1_lot }}</div>
                <div class="game-table-name-cell" :class="{ 'walk-over': game.player1_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready' }">
                  <div class="flag-container" v-if="game.player1_flag"><country-flag :country="game.player1_flag" :title="game.player1_country" size='small'/></div>
                  <label>{{ game.player1_name }}</label>
                </div>
                <div class="game-table-score-cell" :class="game.status">
                  <span>{{ game.player1_score }}</span>
                </div>
                <div class="game-table-handicap" v-if="game.player1_handicap">{{game.player1_handicap}}</div>
              </div>
              <div class="line-connector" @click="isEditor && selectGame(game)">
                <div style="width:21px;"></div>
                <div style="width:124px;" :id="game.id"></div>
              </div>
              <div class="game-table-line" @click="isEditor && selectGame(game)">
                <div class="game-table-player-lot">{{ game.player2_lot }}</div>
                <div class="game-table-name-cell" :class="{ 'walk-over': game.player2_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready'}">
                  <div class="flag-container" v-if="game.player2_flag"><country-flag :country="game.player2_flag" :title="game.player2_country" size='small'/></div>
                  <label>{{ game.player2_name }}</label>
                </div>
                <div class="game-table-score-cell" :class="game.status">
                  <span>{{ game.player2_score }}</span>
                </div>
                <div class="game-table-handicap" v-if="game.player2_handicap">{{game.player2_handicap}}</div>
              </div>
              <div class="game-description">
                <div v-show="game.description" v-if="isEditor && game.description && (game.description.startsWith('winner') || game.description.startsWith('loser')) && game.stage != 'SF'">
                  <label v-if="game.description.startsWith('winner')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">winner to </label>
                  <select v-if="game.description.startsWith('winner')" v-model="game.winner_to"
                    @focus="old_next_game = game.winner_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                    <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
                  </select>
                  <label v-if="game.description.startsWith('loser')" @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">loser to </label>
                  <select v-if="game.description.startsWith('loser')" v-model="game.loser_to"
                    @focus="old_next_game = game.loser_to" @focusout="old_next_game = null" @change="nextGameChange(game)">
                    <option v-for="table in getNextGames(game)" :key="table" :value="table">{{table}}</option>
                  </select>
                </div>
                <label v-else @mouseover="descriptionMouseover(game)" @mouseout="descriptionMouseout(game)" @click="descriptionClick(game)">{{ game.description }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="games.length && scheduling && !till_setup && !loading" v-if="isEditor" id="schedule-container">
        <div v-if="isEditor" id="game-schedule-container">
          <div class="round-schedule" v-for="n in roundsCount" :key="n" :style="{left: (n - 1) * 190 + 'px'}">
            <div :class="{'schedule': true}">
              <input @input="event => updateRoundSchedule(n, event.target.value)" :placeholder="formatFullDateTime(Date.now())" v-if="roundScheduleEnabled(n)">
              <span v-else>X</span>
            </div>
            <i class="fa-solid fa-arrow-down"></i>
          </div>
          <div v-for="game in games" :key="game.id" class="game-table">
            <div class="game-table" :style="{top: (game.location_top + 60) + 'px',left: game.location_left + 'px'}">
              <div class="game-header">
                <div class="game-number">{{ game.game_number }}</div>
              </div>
              <div :class="{'schedule': true}">
                <i class="fas fa-spinner fa-pulse" v-show="game.updating"></i>
                <input :value="formatFullDateTime(game.estimated_time)" @input="event => updateGameSchedule(game, event.target.value)" :placeholder="formatFullDateTime(Date.now())"
                v-if="game.player1 != '0' && game.player2 != '0'"  v-show="!game.updating">
                <span v-else>X</span>
              </div>
              <div class="game-description">
                {{ game.description ? game.description.replace('loser to', 'проигравший на').replace('winner to', 'победитель на') : '' }}
              </div>
            </div>
          </div>
          <svg v-for="(svg, index) in svgs"  :key="index"
            :style="{ left: svg.left + 'px', top: (svg.top + 60) + 'px' }"
            :width="svg.width" :height="svg.height">
            <line v-for="(line, line_index) in svg.lines" :key="line_index"
              :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" style="stroke:#b1b0b0;stroke-width:1" />
          </svg>
        </div>
      </div>
      <div v-show="games.length && !scheduling && till_setup && !loading" v-if="isEditor" id="till-container">
        <h4 v-if="chart_type == 'group'">Групповой этап</h4>
        <div v-if="chart_type == 'group'" class="round-schedule group">
          <div class="till">
            <i class="fa-solid fa-minus" @click="groupTillMinus()"></i>
            <input type="number" min="1" :value="groups_till" @input="event => changeGroupTill(event.target.value)">
            <i class="fa-solid fa-plus" @click="groupTillPlus()"></i>
          </div>
        </div>
        <h4 v-if="chart_type == 'group'">Олимпийка</h4>
        <div id="game-till-container">
          <div class="round-schedule" v-for="n in roundsCount" :key="n" :style="{left: (n - 1) * 190 + 'px'}">
            <div class="till">
              <i class="fa-solid fa-minus" @click="roundTillMinus(n)"></i>
              <input type="number" min="1" :value="roundsTill[n]" @input="event => changeRoundTill(n, event.target.value)">
              <i class="fa-solid fa-plus" @click="roundTillPlus(n)"></i>
            </div>
            <i class="fa-solid fa-arrow-down"></i>
          </div>
          <div v-for="game in fetchedGames" :key="game.id" class="game-table">
            <div class="game-table" :style="{top: (game.location_top + 60) + 'px',left: game.location_left + 'px'}">
              <div class="game-header">
                <div class="game-number">{{ game.game_number }}</div>
              </div>
              <div class="till">
                <i class="fa-solid fa-minus" @click="gameTillMinus(game)" v-show="!game.updating"></i>
                <input type="number" min="1" :value="game.game_till" @input="event => changeGameTill(game, event.target.value)" v-show="!game.updating">
                <i class="fa-solid fa-plus" @click="gameTillPlus(game)" v-show="!game.updating"></i>
                <i class="fas fa-spinner fa-pulse" v-show="game.updating"></i>
              </div>
              <div class="game-description">
                {{ game.description ? game.description.replace('loser to', 'проигравший на').replace('winner to', 'победитель на') : '' }}
              </div>
            </div>
          </div>
          <svg v-for="(svg, index) in svgs"  :key="index"
            :style="{ left: svg.left + 'px', top: (svg.top + 60) + 'px' }"
            :width="svg.width" :height="svg.height">
            <line v-for="(line, line_index) in svg.lines" :key="line_index"
              :x1="line.x1" :y1="line.y1" :x2="line.x2" :y2="line.y2" style="stroke:#b1b0b0;stroke-width:1" />
          </svg>
        </div>
      </div>
      <Transition name="slide-left" v-if="!loading">
        <div id='game-settings' v-if="isEditor && selectedGame && !isMobile" v-show="!loading && !scheduling && !till_setup">
          <div id='game-settings-header'>
            <button @click="selectGame(null)" id="close-button">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
          <div id="loader" v-show="loading">
            <i class="fas fa-spinner fa-pulse"></i>
          </div>
          <div class="actions">
            <router-link :to="`/game/${selectedGame.tournament_id}/${selectedGame.table_number}`" target="_blank" v-show="selectedGame.status == 'running' && selectedGame.table_number && selectedGame.player1_name != 'X' && selectedGame.player2_name != 'X'">
              <i class="fa-solid fa-tablet-screen-button" title="Табло"></i>
            </router-link>
            <a @click="printProtocol()" v-show="selectedGame.player1_name != 'X' && selectedGame.player2_name != 'X'" title="Протокол"><i class="fa-solid fa-print"></i></a>
            <a @click="addYoutube(selectedGame)" title="Привязать трансляцию" v-show="selectedGame.status != 'pending' && selectedGame.player1_name != 'X' && selectedGame.player2_name != 'X'">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <div class="game-controls">
              <a @click="saveGame(selectedGame)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
              <a class="play-button" @click="startGame(selectedGame)" v-show="selectedGame.status == 'ready' && !isNaN(parseInt(selectedGame.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
              <a class="stop-button" @click="stopGame(selectedGame)" v-show="selectedGame.status != 'finished' && selectedGame.status != 'pending' && !isNaN(parseInt(selectedGame.player1_score,10)) && !isNaN(parseInt(selectedGame.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
              <a class="cancel-button" @click="cancelGame(selectedGame)" v-show="(selectedGame.status == 'finished' || selectedGame.status == 'running') && selectedGame.player1 && selectedGame.player2 && selectedGame.player1 != '0' && selectedGame.player2 != '0'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
            </div> 
          </div>
          <div id="game-settings-table" v-show="!loading">
            <div class="game-table">
              <div class="game-header">
                <div class="game-number" v-if="selectedGame.game_number">{{ selectedGame.game_number }}</div>
                <div class="table-number" v-show="selectedGame.status == 'ready' || selectedGame.status == 'running' || selectedGame.status == 'finished'" @focus="$event.target.select()">
                  <span>Стол #</span>
                  <input type="number" v-model="selectedGame.table_name" @blur="gameTableNumberChange(selectedGame)" v-if="!club_tables.length">
                  <select v-else v-model="selectedGame.table_name" @change="gameClubTableChange(selectedGame)">
                    <option value=""></option>
                    <option v-if="(selectedGame.status == 'running' || selectedGame.status == 'finished') && selectedGame.table_number" :value="selectedGame.table_name">{{selectedGame.table_name}}</option>
                    <option v-for="table in freeTables" :key="table.table_number" :value="table.table_name">{{table.table_name}}</option>
                  </select>
                </div>
                <div class="game-time">{{ formatDateTime(selectedGame.start_time) }}</div>
                <div v-show="selectedGame.stage == 'R1' && (switchEnabledParticipants[selectedGame.player1] || switchEnabledParticipants[selectedGame.player2] || selectedGame.player1 == '0' || selectedGame.player2 == '0')" style="width: 54px;"></div>
              </div>
              <div class="game-table-line">
                <div class="game-table-player-lot">{{ selectedGame.player1_lot }}</div>
                <div class="game-table-name-cell" :class="{ 'walk-over': selectedGame.player1_name == 'X' }">
                  <div class="flag-container" v-if="selectedGame.player1_flag"><country-flag :country="selectedGame.player1_flag" :title="selectedGame.player1_country" size='small'/></div>
                  <label>{{ selectedGame.player1_name }}</label>
                </div>
                <div class="game-table-score-cell">
                  <input type="number" v-show="selectedGame.player1 && selectedGame.player1 != '0' && selectedGame.player2 && selectedGame.player2 != '0' && selectedGame.status != 'pending'" v-model="selectedGame.player1_score" @input="switchingPlayer = null; switchingStage = null" @focus="$event.target.select()">
                </div>
                <div class="game-table-handicap" v-if="selectedGame.player1_handicap">{{selectedGame.player1_handicap}}</div>
                <button title="Переставить игрока" 
                v-show="(((chart_type == 'classic' && selectedGame.stage=='R1') || (chart_type == 'group' && selectedGame.stage == 'group') || (chart_type == 'seed' && selectedGame.player2_lot)) && (isSwitchCapable(selectedGame.player1) || selectedGame.player1 == '0')) || 
                (chart_type == 'group' && selectedGame.stage == 'R1' && (isOlympicSwitchCapable(selectedGame.player1) || selectedGame.player1 == '0'))"
                @click="switchParticipantClick(selectedGame.player1_lot, selectedGame)" :class="{ 'active': switchingPlayer && switchingPlayer == selectedGame.player1 }">
                  <i class="fa-solid fa-shuffle"></i>
                </button>
              </div>
              <div class="line-connector">
                <div style="width:21px;"></div>
                <div style="width:124px;" :id="selectedGame.id"></div>
              </div>
              <div class="game-table-line">
                <div class="game-table-player-lot">{{ selectedGame.player2_lot }}</div>
                <div class="game-table-name-cell" :class="{ 'walk-over': selectedGame.player2_name == 'X' }">
                  <div class="flag-container" v-if="selectedGame.player2_flag"><country-flag :country="selectedGame.player2_flag" :title="selectedGame.player2_country" size='small'/></div>
                  <label>{{ selectedGame.player2_name }}</label>
                </div>
                <div class="game-table-score-cell">
                  <input type="number" v-show="selectedGame.player1 && selectedGame.player1 != '0' && selectedGame.player2 && selectedGame.player2 != '0' && selectedGame.status != 'pending'" v-model="selectedGame.player2_score" @input="switchingPlayer = null; switchingStage = null" @focus="$event.target.select()">
                </div>
                <div class="game-table-handicap" v-if="selectedGame.player2_handicap">{{selectedGame.player2_handicap}}</div>
                <button title="Переставить игрока" 
                v-show="(((chart_type == 'classic' && selectedGame.stage=='R1') || (chart_type == 'group' && selectedGame.stage == 'group') || (chart_type == 'seed' && selectedGame.player2_lot)) && (isSwitchCapable(selectedGame.player2) || selectedGame.player2 == '0')) || 
                (chart_type == 'group' && selectedGame.stage == 'R1' && (isOlympicSwitchCapable(selectedGame.player2) || selectedGame.player2 == '0'))"
                @click="switchParticipantClick(selectedGame.player2_lot, selectedGame)" :class="{ 'active':  switchingPlayer && switchingPlayer == selectedGame.player2_lot }">
                  <i class="fa-solid fa-shuffle"></i>
                </button>
              </div>
              <div class="game-footer">
                <span class="game-status">{{selectedGame.status}}</span>
                <div v-show="switchEnabledParticipants[selectedGame.player1] || switchEnabledParticipants[selectedGame.player2]" style="width: 54px;"></div>
              </div>
              <label v-if="selectedGame.game_till">{{gameType == 2 ? `Игра до ${selectedGame.game_till} шаров` : `Игра до ${selectedGame.game_till} побед`}}</label>
            </div>
          </div>
          <div id="game-settings-switch-list" v-show="switchingPlayer && !loading">
            <ul>
              <li v-for="player in switchingList" :key="player.id" @click="switchPlayers(switchingPlayer, player.lot)">
                {{ player.name }}
              </li>
            </ul>
          </div>
        </div>
      </Transition>
      <div id="print-content" v-if="isEditor && selectedGame" v-show="false">
        <div id="header">
          <label>{{`Протокол встречи № ${selectedGame.game_number}`}}</label>
          <label>{{`Дата: ${formatDate(Date.now())}`}}</label>
        </div>
        <div id="second-header">
          <label>{{`Стол № ${selectedGame.table_name ? selectedGame.table_name : '_____' }, Игра до ${selectedGame.game_till ? selectedGame.game_till : '_____' } ${parseInt(gameType,10) == 2 ? 'шаров' : 'побед'}`}}</label>
          <label id="start-time">{{`Начало: ${selectedGame.start_time ? formatTime(selectedGame.start_time) : '__________'}  Окончание: __________`}}</label>
        </div>
        <table id="score-table">
          <tr>
            <th>Игрок</th>
            <th v-for="n in selectedGame.max_frames" :key="n" class="frame-cell">{{n}}</th>
            <th>счёт</th>
            <th>подпись</th>
          </tr>
          <tr>
            <td>{{ selectedGame.player1_name ? selectedGame.player1_name : ' ' }}</td>
            <th v-for="n in selectedGame.max_frames" :key="n" class="frame-cell"></th>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{{ selectedGame.player2_name ? selectedGame.player2_name : ' '}}</td>
            <th v-for="n in selectedGame.max_frames" :key="n" class="frame-cell"></th>
            <td></td>
            <td></td>
          </tr>
        </table>
        <div id="signatures">
          <div class="sign">
            <label>{{ selectedGame.player1_name }}</label>
          </div>
          <div class="sign">
            <label>{{ selectedGame.player2_name }}</label>
          </div>
        </div>
      </div>
      <Transition name="table-expand">
        <div id="tables-container" v-if="isEditor && !isMobile && club_tables.length" v-show="!loading && !scheduling && !till_setup" :class="{'expanded': tables_container_visible}">
          <button class="expand-button" @click="tables_container_visible = !tables_container_visible" :class="{'closed': !tables_container_visible}">
            <i class="fa-solid fa-angles-up" v-if="!tables_container_visible"></i>
            <i class="fa-solid fa-angles-down" v-else></i>
            <span>Столы</span>
            <i class="fa-solid fa-angles-up" v-if="!tables_container_visible"></i>
            <i class="fa-solid fa-angles-down" v-else></i>
          </button>
          <div class="tables-list" v-show="tables_container_visible">
            <div class="table" v-for="(table, index) in club_tables" :key="table.id" :class="{'last': index == club_tables.length - 1}">
              <div class="cell">
                <div>
                  <label>Стол</label>
                </div>
                <span>{{table.table_name}}</span>
              </div>
              <i v-if="table.loading" class="fas fa-spinner fa-pulse"></i>
              <div class="cell" v-else>
                <div>
                  <label>Статус</label>
                </div>
                <select :id="table.id + '-table-status'" v-model="table.status" @change="saveTable(table)" :disabled="table.loading">
                  <option v-for="status in table_statuses" :key="status.value" :value="status.value">{{status.str}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <div class='personal-chart-container' v-else>
      <div v-for="game in personalChart.games" :key="game.id" class="personal-game" :style="{top: game.location_top + 'px',left: game.location_left + 'px'}">
        <label class="stage">{{game.stage}}</label>
        <div :id="game.game_number + '-game'" class="game-table">
          <div class="game-header">
            <div class="youtube-link" v-if="game.youtube_link">
              <a :href="game.youtube_link" target="_blank">
                <i class="fa-brands fa-youtube"></i>
              </a>
            </div>
            <div class="game-number" @mouseover="gameNumberMouseover(game)" @mouseout="gameNumberMouseout(game)" @click="gameNumberClick(game)">{{ game.game_number }}</div>
            <div class="table-number" v-show="game.table_name">{{ `Стол #${game.table_name}` }}</div>
            <div class="game-time">{{ game.time }}</div>
          </div>
          <div class="game-table-line" @click="isEditor && selectGame(game)">
            <div class="game-table-name-cell" :class="{ 'walk-over': game.player1_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready' }">
              <div class="flag-container" v-if="game.player1_flag"><country-flag :country="game.player1_flag" :title="game.player1_country" size='small'/></div>
              <label>{{ game.player1_name }}</label>
            </div>
            <div class="game-table-score-cell" :class="game.status">
              <span>{{ game.player1_score }}</span>
            </div>
            <div class="game-table-handicap" v-if="game.player1_handicap">{{game.player1_handicap}}</div>
          </div>
          <div class="line-connector" @click="isEditor && selectGame(game)">
            <div style="width:21px;"></div>
            <div style="width:124px;" :id="game.id"></div>
          </div>
          <div class="game-table-line" @click="isEditor && selectGame(game)">
            <div class="game-table-name-cell" :class="{ 'walk-over': game.player2_name == 'X', 'running': game.status == 'running', 'ready': game.status == 'ready'}">
              <div class="flag-container" v-if="game.player2_flag"><country-flag :country="game.player2_flag" :title="game.player2_country" size='small'/></div>
              <label>{{ game.player2_name }}</label>
            </div>
            <div class="game-table-score-cell" :class="game.status">
              <span>{{ game.player2_score }}</span>
            </div>
            <div class="game-table-handicap" v-if="game.player2_handicap">{{game.player2_handicap}}</div>
          </div>
        </div>
      </div>
      <svg v-for="(svg, index) in personalChart.svgs"  :key="index"
        :style="{ left: svg.left + 'px', top: svg.top + 'px' }"
        :width="svg.width" :height="svg.height">
        <path :d="`M ${svg.start.x} ${svg.start.y} Q ${svg.start.x} ${svg.height/2}, ${(svg.end.x + svg.start.x)/2} ${svg.height/2} T ${svg.end.x} ${svg.end.y}`" :stroke="svg.color" stroke-width="1.5" fill="transparent"/>
      </svg>
      <div v-for="endpoint in personalChart.endpoints" :key="endpoint.location_left" :style="{top: endpoint.location_top + 'px',left: endpoint.location_left + 'px'}" class="endpoint" :class="endpoint.type">
        <i v-if="endpoint.type == 'fail'" class="fa-solid fa-xmark"></i>
        <i v-else class="fa-solid fa-medal"></i>
        <label v-if="endpoint.type == 'first'">1-ое место</label>
        <label v-if="endpoint.type == 'second'">2-ое место</label>
        <label v-if="endpoint.type == 'third'">3-е место</label>
        <label v-if="endpoint.type == 'fourth'">4-ое место</label>
      </div>
    </div>
    <div class="chart-notifications" v-if="isEditor" v-show="notifications.length">
      <div v-for="notification in notifications" :key="notification" class="chart-notification">{{ notification }}</div>
    </div>
    <Transition name="slide-top">
      <div id='game-settings-mobile' v-if="isEditor && selectedGame && isMobile" v-show="!loading && !scheduling && !till_setup">
        <div id='game-settings-header'>
          <button @click="selectGame(null)" id="close-button">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <div class="actions">
            <router-link :to="`/game/${selectedGame.tournament_id}/${selectedGame.table_number}`" target="_blank" v-show="selectedGame.status == 'running' && selectedGame.table_number && selectedGame.player1_name != 'X' && selectedGame.player2_name != 'X'">
              <i class="fa-solid fa-tablet-screen-button" title="Табло"></i>
            </router-link>
            <a @click="printProtocol()" v-show="selectedGame.player1_name != 'X' && selectedGame.player2_name != 'X'" title="Протокол"><i class="fa-solid fa-print"></i></a>
            <a @click="addYoutube(selectedGame)" title="Привязать трансляцию" v-show="selectedGame.status != 'pending' && selectedGame.player1_name != 'X' && selectedGame.player2_name != 'X'">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <div class="game-controls">
              <a @click="saveGame(selectedGame)" title="Сохранить"><i class="fa-solid fa-floppy-disk"></i></a>
              <a class="play-button" @click="startGame(selectedGame)" v-show="selectedGame.status == 'ready' && !isNaN(parseInt(selectedGame.table_number,10))" title="Начать встречу"><i class="fa-solid fa-play"></i></a>
              <a class="stop-button" @click="stopGame(selectedGame)" v-show="selectedGame.status != 'finished' && selectedGame.status != 'pending' && !isNaN(parseInt(selectedGame.player1_score,10)) && !isNaN(parseInt(selectedGame.player2_score,10))" title="Закончить встречу"><i class="fa-solid fa-stop"></i></a>
              <a class="cancel-button" @click="cancelGame(selectedGame)" v-show="(selectedGame.status == 'finished' || selectedGame.status == 'running') && selectedGame.player1 && selectedGame.player2 && selectedGame.player1 != '0' && selectedGame.player2 != '0'" title="Отменить встречу"><i class="fa-solid fa-ban"></i></a>
            </div> 
          </div>
        </div>
        <div id="loader" v-show="loading">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
        <div id="game-settings-table" v-show="!loading">
          <div class="game-table">
            <div class="game-header">
              <div class="game-number" v-if="selectedGame.game_number">{{ selectedGame.game_number }}</div>
              <div class="table-number" v-show="selectedGame.status == 'ready' || selectedGame.status == 'running' || selectedGame.status == 'finished'">
                <span>Стол #</span>
                <input type="number" v-model="selectedGame.table_name" @blur="gameTableNumberChange(selectedGame)" @focus="$event.target.select()" v-if="!club_tables.length" >
                <select v-else v-model="selectedGame.table_name" @change="gameClubTableChange(selectedGame)">
                  <option value=""></option>
                  <option v-if="(selectedGame.status == 'running' || selectedGame.status == 'finished') && selectedGame.table_number" :value="selectedGame.table_name">{{selectedGame.table_name}}</option>
                  <option v-for="table in freeTables" :key="table.table_number" :value="table.table_name">{{table.table_name}}</option>
                </select>
              </div>
              <div class="game-time">{{ formatDateTime(selectedGame.start_time) }}</div>
              <div v-show="selectedGame.stage == 'R1' && (switchEnabledParticipants[selectedGame.player1] || switchEnabledParticipants[selectedGame.player2] || selectedGame.player1 == '0' || selectedGame.player2 == '0')" style="width: 54px;"></div>
            </div>
            <div class="game-table-line">
              <div class="game-table-player-lot">{{ selectedGame.player1_lot }}</div>
              <div class="game-table-name-cell" :class="{ 'walk-over': selectedGame.player1_name == 'X', 'running': selectedGame.status == 'running', 'ready': selectedGame.status == 'ready'}">
                <div class="flag-container" v-if="selectedGame.player1_flag"><country-flag :country="selectedGame.player1_flag" :title="selectedGame.player1_country" size='small'/></div>
                <label>{{ selectedGame.player1_name }}</label>
              </div>
              <div class="game-table-score-cell">
                <input type="number" v-show="selectedGame.player1 && selectedGame.player1 != '0' && selectedGame.player2 && selectedGame.player2 != '0'  && selectedGame.status != 'pending'" v-model="selectedGame.player1_score" @input="switchingPlayer = null; switchingStage = null" @focus="$event.target.select()">
              </div>
              <div class="game-table-handicap" v-if="selectedGame.player1_handicap">{{selectedGame.player1_handicap}}</div>
              <button title="Переставить игрока"
              v-show="(((chart_type == 'classic' && selectedGame.stage=='R1') || (chart_type == 'group' && selectedGame.stage == 'group') || (chart_type == 'seed' && selectedGame.player2_lot)) && (isSwitchCapable(selectedGame.player1) || selectedGame.player1 == '0')) || 
                (chart_type == 'group' && selectedGame.stage == 'R1' && (isOlympicSwitchCapable(selectedGame.player1) || selectedGame.player1 == '0'))"
              @click="switchParticipantClick(selectedGame.player1_lot, selectedGame)" :class="{ 'active': switchingPlayer && switchingPlayer == selectedGame.player1_lot }">
                <i class="fa-solid fa-shuffle"></i>
              </button>
            </div>
            <div class="line-connector">
              <div style="width:21px;"></div>
              <div style="width:124px;" :id="selectedGame.id"></div>
            </div>
            <div class="game-table-line">
              <div class="game-table-player-lot">{{ selectedGame.player2_lot }}</div>
              <div class="game-table-name-cell" :class="{ 'walk-over': selectedGame.player2_name == 'X', 'running': selectedGame.status == 'running', 'ready': selectedGame.status == 'ready'}">
                <div class="flag-container" v-if="selectedGame.player2_flag"><country-flag :country="selectedGame.player2_flag" :title="selectedGame.player2_country" size='small'/></div>
                <label>{{ selectedGame.player2_name }}</label>
              </div>
              <div class="game-table-score-cell">
                <input type="number" v-show="selectedGame.player1 && selectedGame.player1 != '0' && selectedGame.player2 && selectedGame.player2 != '0'  && selectedGame.status != 'pending'" v-model="selectedGame.player2_score" @input="switchingPlayer = null; switchingStage = null" @focus="$event.target.select()">
              </div>
              <div class="game-table-handicap" v-if="selectedGame.player2_handicap">{{selectedGame.player2_handicap}}</div>
              <button title="Переставить игрока"
              v-show="(((chart_type == 'classic' && selectedGame.stage=='R1') || (chart_type == 'group' && selectedGame.stage == 'group') || (chart_type == 'seed' && selectedGame.player2_lot)) && (isSwitchCapable(selectedGame.player2) || selectedGame.player2 == '0')) || 
                (chart_type == 'group' && selectedGame.stage == 'R1' && (isOlympicSwitchCapable(selectedGame.player2) || selectedGame.player2 == '0'))"
              @click="switchParticipantClick(selectedGame.player2_lot, selectedGame)" :class="{ 'active':  switchingPlayer && switchingPlayer == selectedGame.player2_lot }">
                <i class="fa-solid fa-shuffle"></i>
              </button>
            </div>
            <div class="game-footer">
              <span class="game-status">{{selectedGame.status}}</span>
              <div v-show="switchEnabledParticipants[selectedGame.player1] || switchEnabledParticipants[selectedGame.player2]" style="width: 54px;"></div>
            </div>
            <label v-if="selectedGame.game_till">{{gameType == 2 ? `Игра до ${selectedGame.game_till} шаров` : `Игра до ${selectedGame.game_till} побед`}}</label>
          </div>
        </div>
        <div id="game-settings-switch-list" v-show="switchingPlayer && !loading">
          <ul>
            <li v-for="player in switchingList" :key="player.id" @click="switchPlayers(switchingPlayer, player.lot)">
              {{ player.name }}
            </li>
          </ul>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import axios from 'axios';
import Toggle from '@vueform/toggle';
import "@vueform/toggle/themes/default.css";
import CountryFlag from 'vue-country-flag-next'
export default {
  name: 'TournamentGames',
  components: {
    'v-toggle': Toggle,
    'country-flag': CountryFlag
  },
  data: function() {
    return {
      username: null,
      role: null,
      tournament_id: null,
      tournament_name: null,
      tournament_team: false,
      tournament_status: null,
      teams: [],
      team_members: [],
      gameType: null,
      owner: '',
      fetchedParticipants: [],
      handicap_id: null,
      handicap_relations: null,
      rating_id: null,
      fetchedGames: [],
      loading: false,
      leaderLines: [],
      newChart: {
        num_players: null,
        num_final_players: null,
        third_place_game: false,
        lot_by_region: false,
        lot_by_rating: false,
        chart_type: 'classic',
        num_groups: null,
        num_group_winners: null,
        seed_rounds: [],
      },
      error: '',
      selectedGame: null,
      switchingPlayer: null,
      switchingStage: null,
      creatingChart: false,
      deletingChart: false,
      editingChart: false,
      scheduling: false,
      till_setup: false,
      wb_till: null,
      lb_till: null,
      club_id: null,
      club: null,
      club_tables: [],
      games_previous_tables: {},
      old_next_game: null,
      chart_type: 'classic',
      olympic_games_visible: false,
      group_games_visible: true,
      groups_till: null,
      num_group_winners: null,
      num_groups: null,
      tables_container_visible: false,
      table_statuses: [
        {value: 'open', str: 'Занят'},
        {value: 'close', str: 'Свободен'}
      ],
      search: {
        str: "",
        player_name: null,
        player_id: null,
        list_show: false,
      },
      settings_dropdown_hover: false,
      settings_dropdown_expanded: false,
      changing_status: false,
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    },
    games () { 
      return this.fetchedGames.map((game) => {
        if (game.stage == 'group') {
          this.groups_till = game.game_till;
        }
        game.location_left = (game.location_x - 1) * 190;
        game.location_top = (game.location_y - 1) * 38;
        let found = 0;
        if (game.player1 == '0') {
          game.player1_name = 'X';
          game.player1_flag = null;
          found++;
        }
        if (game.player2 == '0') {
          game.player2_name = 'X';
          game.player2_flag = null;
          found++;
        }
        if (!this.tournament_team) {
          this.fetchedParticipants.forEach(part => {
            if (part.id == game.player1) {
              if (game.stage == 'group') game.group = part.chart_group;
              game.player1_name = part.name;
              game.player1_flag = part.country_label;
              game.player1_country = part.country_name;
              if (part.name) {
                let name_parts = part.name.split(' ');
                if (name_parts.length > 2) {
                  if (name_parts[1].match(/[А-Я]/)) {
                    game.player1_name = name_parts[0] + ' ' + name_parts[1];
                  } else {
                    game.player1_name = name_parts[0] + ' ' + name_parts[1] + ' ' + name_parts[2];
                  }
                }
              }
              if (part.group) game.player1_group = part.group;
              found++;
            }
            if (part.id == game.player2) {
              game.player2_name = part.name;
              game.player2_flag = part.country_label;
              game.player2_country = part.country_name;
              if (part.name) {
                let name_parts = part.name.split(' ');
                if (name_parts.length > 2) {
                  if (name_parts[1].match(/[А-Я]/)) {
                    game.player2_name = name_parts[0] + ' ' + name_parts[1];
                  } else {
                    game.player2_name = name_parts[0] + ' ' + name_parts[1] + ' ' + name_parts[2];
                  }
                }
              }
              if (part.group) game.player2_group = part.group;
              found++;
            }
            if (found > 1) return
          });
        } else {
          if (game.stage == 'group') game.group = game.chart_group;
          if (game.player1 && game.player1 != '0') {
            game.player1_name = this.teamsById[this.participants_by_id[game.player1][0].team_id][0].name;
            if (this.handicap_id) {
              game.player1_group = this.teamHandicapGroup(this.participants_by_id[game.player1][0].team_id);
            }
          }
          if (game.player2 && game.player2 != '0') {
            game.player2_name = this.teamsById[this.participants_by_id[game.player2][0].team_id][0].name;
            if (this.handicap_id) {
              game.player2_group = this.teamHandicapGroup(this.participants_by_id[game.player2][0].team_id);
            }
          }
        }
        if (this.handicap_relations && game.player1_group && game.player2_group) {
          if (game.player1_group == game.player2_group) {
            if (this.gameType != 2) {
              game.player1_handicap = 8;
              game.player2_handicap = 8;
            } else {
              game.player1_handicap = game.game_till;
              game.player2_handicap = game.game_till;
            }
          } else {
            if (this.gameType != 2) {
              if (this.handicap_relations[8]) {
                game.player1_handicap = this.handicap_relations[8][game.player1_group][game.player2_group];
                game.player2_handicap = this.handicap_relations[8][game.player2_group][game.player1_group];
              }
            } else {
              if (this.handicap_relations[game.game_till]) {
                game.player1_handicap = this.handicap_relations[game.game_till][game.player1_group][game.player2_group];
                game.player2_handicap = this.handicap_relations[game.game_till][game.player2_group][game.player1_group];
              }
            }
          }
        }
        if (!game.game_till) {
          if (game.stage.startsWith('LB')) {
            game.game_till = this.lb_till;
          } else {
            game.game_till = this.wb_till;
          }
        }
        if (this.gameType != 2) {
          game.max_frames = 2 * game.game_till - 1;
        } else {
          game.max_frames = 1;
        }
        game.table_name = game.table_number;
        if (game.table_number && this.club_tables.length) {
          this.club_tables.forEach(table => {
            if (table.table_number == game.table_number) {
              game.table_name = table.table_name;
              return
            }
          });
        }
        if (game.youtube_link) {
          if (game.youtube_link.includes('vk.com/video_ext.php')) {
            let url = new URL(game.youtube_link);
            let urlParams = new URLSearchParams(url.search);
            let video_id = urlParams.get('id');
            let owner_id = urlParams.get('oid');
            if (video_id && owner_id) {
              game.youtube_link = `https://vk.com/video${owner_id}_${video_id}`;
            }
          }
        }
        game.time = '';
        if (game.status == 'finished' || game.status == 'running') {
          if (game.start_time) {
            game.time = this.formatDateTime(game.start_time)
          } else {
            game.time = this.formatDateTime(game.end_time)
          }
        } else {
          game.time = this.formatDateTime(game.estimated_time);
        }
        return game;
      });
    },
    teamsById() {
      if (!this.tournament_team) return null;
      return this.groupArrayBy(this.teams, 'id');
    },
    groups () {
      let result = [];
      this.games.forEach(game => {
        if (game.stage != 'group') return
        let group = null;
        for (let g=0; g<result.length; g++) {
          if (result[g].name == game.group) {
            group = result[g];
            break;
          }
        }
        if (!group) {
          group = {
            name: game.group,
            games: [],
            participants: [],
            scores: {}
          }
          result.push(group);
        }
        group.games.push(game);
        if (!group.scores[game.player1]) group.scores[game.player1] = {};
        if (!group.scores[game.player1][game.player2]) group.scores[game.player1][game.player2] = {};
        if (!isNaN(parseInt(game.player1_score,10))) {
          group.scores[game.player1][game.player2].score = game.player1_score + ':' + game.player2_score;
        } else {
          group.scores[game.player1][game.player2].score = '-';
        }
        group.scores[game.player1][game.player2].game = game.id;
        group.scores[game.player1][game.player2].status = game.status;
        if (!group.scores[game.player2]) group.scores[game.player2] = {};
        if (!group.scores[game.player2][game.player1]) group.scores[game.player2][game.player1] = {};
        if (!isNaN(parseInt(game.player1_score,10))) {
          group.scores[game.player2][game.player1].score = game.player2_score + ':' + game.player1_score;
        } else {
          group.scores[game.player2][game.player1].score = '-';
        }
        group.scores[game.player2][game.player1].game = game.id;
        group.scores[game.player2][game.player1].status = game.status;
        let player1_found = false;
        let player2_found = false;
        for (let p=0; p<group.participants.length; p++){
          if (group.participants[p].id == game.player1) {
            player1_found = true;
            group.participants[p].wins += game.player1_score;
            group.participants[p].fails += game.player2_score;
          }
          if (group.participants[p].id == game.player2) {
            player2_found = true;
            group.participants[p].wins += game.player2_score;
            group.participants[p].fails += game.player1_score;
          }
          if (player1_found && player2_found) break;
        }
        if (!player1_found) {
          let part = this.participants_by_id[game.player1][0];
          part.wins = game.player1_score;
          part.fails = game.player2_score;
          if (part.team_id) {
            part.name = this.teamsById[part.team_id][0].name
          }
          group.participants.push(part);
          group.scores[game.player1][game.player1] = {};
          group.scores[game.player1][game.player1].status = 'X';
        }
        if (!player2_found) {
          let part = this.participants_by_id[game.player2][0];
          part.wins = game.player2_score;
          part.fails = game.player1_score;
          if (part.team_id) {
            part.name = this.teamsById[part.team_id][0].name
          }
          group.participants.push(part);
          group.scores[game.player2][game.player2] = {};
          group.scores[game.player2][game.player2].status = 'X';
        }
      });
      result.forEach(group => {
        let participants_by_id = this.groupArrayBy(group.participants, 'id');
        let sorted_parts = [...group.participants];
        sorted_parts.sort((a,b) => {
          if (a.group_points > b.group_points) return -1
          if (a.group_points < b.group_points) return 1
          if (a.wins > b.wins) return -1
          if (a.wins < b.wins) return 1
          if (a.fails > b.fails) return 1
          if (a.fails < b.fails) return -1
          return 0
        })
        for (let i=0; i<sorted_parts.length; i++) {
          participants_by_id[sorted_parts[i].id][0].group_place = i+1;
        }
      });
      return result;
    },
    svgs () {
      let svgs = [];
      this.games.forEach(game => {
        if (game.stage == 'group') return
        if (game.stage == 'P3') return
        let neighbors = [];
        this.games.forEach(neighbor_game => {
          if (neighbor_game.game_number == game.game_number) return;
          if (neighbor_game.stage == "P3") return;
          if (neighbor_game.location_x - game.location_x != 1) return;
          if (neighbors.length == 0) {
            neighbors.push(neighbor_game);
            return;
          }
          if (Math.abs(neighbor_game.location_y - game.location_y) > Math.abs(neighbors[0].location_y - game.location_y)) {
            return;
          } else if (Math.abs(neighbor_game.location_y - game.location_y) == Math.abs(neighbors[0].location_y - game.location_y)) {
            neighbors.push(neighbor_game);
          } else {
            neighbors = [];
            neighbors.push(neighbor_game);
          }
        });
        neighbors.forEach(neighbor_game => {
          let svg = {};
          svg.lines = [];
          if (neighbor_game.location_y == game.location_y) {
            svg.left = game.location_left + 155;
            svg.top = game.location_top + 16;
            svg.width = 56;
            svg.height = 38;
            svg.lines.push({x1:0, y1:20, x2:56, y2:20});
          } else if (neighbors.length == 1) {
            svg.left = game.location_left + 155;
            svg.width = 108;
            if (game.location_y < neighbor_game.location_y) {
              svg.top = game.location_top + 16;
              svg.height = (neighbor_game.location_top + 16) - svg.top;
              svg.lines.push({x1:0, y1:20, x2:svg.width-1, y2:20});
              svg.lines.push({x1:svg.width-1, y1:20, x2:svg.width-1, y2:svg.height});
            } else {
              svg.top = neighbor_game.location_top + 54;
              svg.height = game.location_top + 54 - svg.top;
              svg.lines.push({x1:0, y1:svg.height-18, x2:svg.width-1, y2:svg.height-18});
              svg.lines.push({x1:svg.width-1, y1:svg.height-18, x2:svg.width-1, y2:0});
            }
          } else {
            svg.left = game.location_left + 72;
            svg.width = neighbor_game.location_left + 21 - svg.left;
            if (game.location_y < neighbor_game.location_y) {
              svg.top = game.location_top + 54;
              svg.height = neighbor_game.location_top + 54 - svg.top;
              svg.lines.push({x1:1, y1:0, x2:1, y2:svg.height-18});
              svg.lines.push({x1:1, y1:svg.height-18, x2:svg.width, y2:svg.height-18});
            } else {
              svg.top = neighbor_game.location_top + 16;
              svg.height = game.location_top + 16 - svg.top;
              svg.lines.push({x1:1, y1:svg.height, x2:1, y2:20});
              svg.lines.push({x1:1, y1:20, x2:svg.width, y2:20});
            }
          }
          svgs.push(svg);
        });
      });
      return svgs
    },
    switchEnabledParticipants() {
      let result = JSON.parse(JSON.stringify(this.fetchedParticipants));
      this.games.forEach(game => {
        if (game.status == 'running' || (game.status == 'finished' && game.player1 != 0 && game.player2 != 0)) {
          for (let i = 0; i<result.length; i++) {
            let part = result[i];
            if (part.id == game.player1 || part.id == game.player2) {
              result.splice(i, 1);
              i--;
            }
          }
        }
      });
      return result
    },
    olympicSwitchEnabledParticipants() {
      let result = JSON.parse(JSON.stringify(this.fetchedParticipants));
      this.games.forEach(game => {
        if (game.stage != 'group') {
          if (game.status == 'running' || (game.status == 'finished' && game.player1 != 0 && game.player2 != 0)) {
            for (let i = 0; i<result.length; i++) {
              let part = result[i];
              if (part.id == game.player1 || part.id == game.player2) {
                result.splice(i, 1);
                i--;
              }
            }
          }
        }
      });
      return result
    },
    switchingList() {
      if (!this.switchingPlayer || !this.switchingStage) return null;
      let result = null;
      if (this.switchingStage == 'initial') {
        result = this.switchEnabledParticipants.filter(part => {
          return this.switchingPlayer != part.lot && (this.chart_type != 'group' || this.participants_by_lot[this.switchingPlayer][0].chart_group != part.chart_group);
        });
      } else if (this.switchingStage == 'olympic') {
        let switchingPlayerId = null;
        for (let i=0; i<this.games.length; i++) {
          if (this.games[i].stage != 'R1') continue;
          if (this.games[i].player1_lot == this.switchingPlayer) {
            switchingPlayerId = this.games[i].player1;
          } else if (this.games[i].player2_lot == this.switchingPlayer) {
            switchingPlayerId = this.games[i].player2;
          }
        }
        result = this.olympicSwitchEnabledParticipants.filter(part => {
          return switchingPlayerId != part.id;
        });
      }
      if (result && this.tournament_team) {
        result.forEach(player => {
          player.name = this.teamsById[player.team_id][0].name;
        });
      }
      result.sort((a, b) => {
        if (b.name.toLowerCase() < a.name.toLowerCase()) return 1;
        else return -1
      });
      return result;
    },
    roundsCount() {
      let rounds = {};
      let count = 0;
      this.fetchedGames.forEach(game => {
        if (game.location_x != null && !rounds[game.location_x]) {
          rounds[game.location_x] = true;
          count++;
        }
      });
      return count;
    },
    roundsTill() {
      let result = {};
      this.fetchedGames.forEach(game => {
        if (!result[game.location_x]) {
          result[game.location_x] = game.game_till;
        } else if (result[game.location_x] != game.game_till) {
          result[game.location_x] = -1;
        }
      });
      for (let i = 0; i<Object.keys(result).length; i++) {
        if (result[Object.keys(result)[i]] == -1) {
          delete result[Object.keys(result)[i]];
          i--
        }
      }
      return result
    },
    isMobile() {
      return screen.width <= 1000;
    },
    freeTables() {
      let tables_by_status = this.groupArrayBy(this.club_tables, 'status');
      return tables_by_status.close;
    },
    gamesByLocationX() {
      return this.groupArrayBy(this.games, 'location_x');
    },
    gamesByStage() {
      return this.groupArrayBy(this.games, 'stage');
    },
    gamesByNumber() {
      return this.groupArrayBy(this.games, 'game_number');
    },
    participants_by_id() {
      return this.groupArrayBy(this.fetchedParticipants, 'id');
    },
    participants_by_lot() {
      return this.groupArrayBy(this.fetchedParticipants, 'lot');
    },
    games_by_id() {
      return this.groupArrayBy(this.games, 'id');
    },
    searchPlayersList() {
      let parts = this.fetchedParticipants;
      if (this.tournament_team) {
        parts.forEach(part => {
          part.name = this.teamsById[part.team_id][0].name;
        })
      }
      if (!this.search.str) {
        return parts;
      }
      return parts.filter(participant => {
        return participant.name.toLowerCase().includes(this.search.str.toLowerCase());
      });
    },
    personalChart() {
      if (!this.search.player_id) return null;
      let result = {
        games: [],
        svgs: [],
        endpoints: [],
      };
      let r1_game = null;
      for (let i=0; i<this.gamesByStage['R1'].length; i++) {
        if (this.gamesByStage['R1'][i].player1 == this.search.player_id || this.gamesByStage['R1'][i].player2 == this.search.player_id) {
          r1_game = { ...this.gamesByStage['R1'][i] };
          break;
        }
      }
      if (!r1_game) return null;

      r1_game.location_x = 1;
      r1_game.location_y = 1;
      r1_game.location_left = (r1_game.location_x - 1) * 196;
      r1_game.location_top = 50;
      result.games.push(r1_game);
      let next_game_number;
      let winner = true;
      if (r1_game.status != 'finished') next_game_number = null;
      else if (r1_game.loser == this.search.player_id) {
        next_game_number = r1_game.loser_to;
        winner = false;
      }
      else next_game_number = r1_game.winner_to;

      while (next_game_number) {
        let next_game = { ...this.gamesByNumber[next_game_number][0] };
        next_game.location_x = 1;
        next_game.location_y = result.games[result.games.length - 1].location_y + 1;
        next_game.location_left = (next_game.location_x - 1) * 196;
        next_game.location_top = (next_game.location_y - 1) * 100 + 50;
        result.games.push(next_game);

        let svg = {};
        svg.left = next_game.location_left;
        svg.top = next_game.location_top - 46;
        svg.width = 196;
        svg.height = 62;
        svg.start = {x:svg.width/2-5, y:0};
        svg.end = {x:svg.width/2-5, y:svg.height};
        svg.color = 'red';
        if (winner) {
          svg.color = 'green';
        }
        result.svgs.push(svg);

        winner = true;
        if (next_game.loser == this.search.player_id) winner = false;
        next_game_number = next_game.loser_to;
        if (winner) {
          next_game_number = next_game.winner_to;
        }
        
        //Move current game rigth
        if (next_game.status != 'finished') {
          next_game_number = null;
        }
        if (next_game.status == 'pending') {
          next_game.location_x += 0.5;
          next_game.location_left = (next_game.location_x - 1) * 196;
          svg.width += 95;
          svg.start = {x:svg.width/3+3-5, y:0};
          svg.end = {x:svg.width*2/3-3-5, y:svg.height};
        }
      }
      let current_game = result.games[result.games.length - 1];
      if (current_game.location_x == 1 && current_game.status != 'finished') {
        result.games.forEach(game => {
          game.location_x += 0.5;
          game.location_left = (game.location_x - 1) * 196;
        });
        result.svgs.forEach(svg => {
          svg.left += 95;
        });
      }
      if (current_game.status == 'pending') {
        let previous_game = result.games[result.games.length - 2];
        let wait_game = null;
        for (let i=0; i<this.games.length; i++) {
          let game = this.games[i];
          if (game.game_number != previous_game.game_number && 
              (game.winner_to == current_game.game_number ||
               game.loser_to == current_game.game_number)) {
            wait_game = { ...game };
            result.games.push(wait_game);
            if (wait_game.status != 'pending') {
              wait_game.location_x = current_game.location_x + 0.5;
            } else {
              wait_game.location_x = current_game.location_x + 1;
            }
            wait_game.location_y = current_game.location_y - 1;
            wait_game.location_left = (wait_game.location_x - 1) * 196;
            wait_game.location_top = (wait_game.location_y - 1) * 100 + 50;
            let svg = {};
            svg.left = current_game.location_left;
            svg.top = current_game.location_top - 46;
            if (wait_game.status != 'pending') {
              svg.width = 196 + 98;
            } else {
              svg.width = 196 * 2;
            }
            svg.height = 62;
            if (wait_game.status != 'pending') {
              svg.start = {x:svg.width*2/3-3-5, y:0};
              svg.end = {x:svg.width/3+3-5, y:svg.height};
            } else {
              svg.start = {x:svg.width*3/4-3-5, y:0};
              svg.end = {x:svg.width/4+3-5, y:svg.height};
            }
            if (wait_game.winner_to == current_game.game_number) {
              svg.color = 'green';
            } else {
              svg.color = 'red';
            }
            result.svgs.push(svg);
            break;
          }
        }
        //wait_wait_games
        if (wait_game.status == 'pending') {        
          let first_wait_wait = true;  
          for (let i=0; i<this.games.length; i++) {
            let game = this.games[i];
            if (game.winner_to == wait_game.game_number ||
                game.loser_to == wait_game.game_number) {
              let wait_wait_game = { ...game };
              result.games.push(wait_wait_game);
              if (first_wait_wait) {
                wait_wait_game.location_x = wait_game.location_x - 0.5;
              } else {
                wait_wait_game.location_x = wait_game.location_x + 0.5;
              }
              wait_wait_game.location_y = wait_game.location_y - 1;
              wait_wait_game.location_left = (wait_wait_game.location_x - 1) * 196;
              wait_wait_game.location_top = (wait_wait_game.location_y - 1) * 100 + 50;
              let svg = {};
              if (first_wait_wait) {
                svg.left = wait_wait_game.location_left;
              } else {
                svg.left = wait_game.location_left;
              }
              svg.top = wait_game.location_top - 46;
              svg.width = 196 + 98;
              svg.height = 62;
              if (first_wait_wait) {
                svg.start = {x:svg.width/3+3-5, y:0};
                svg.end = {x:svg.width*2/3-3-5, y:svg.height};
              } else {
                svg.start = {x:svg.width*2/3-3-5, y:0};
                svg.end = {x:svg.width/3+3-5, y:svg.height};
              }
              if (wait_wait_game.winner_to == wait_game.game_number) {
                svg.color = 'green';
              } else {
                svg.color = 'red';
              }
              result.svgs.push(svg);

              if (!first_wait_wait) break;
              first_wait_wait = false;
            }
          }
        }
      }
      if (current_game.status != 'finished') {
        if (current_game.winner_to) {
          let end = false;
          let location_y = current_game.location_y;
          let svg_width = 196 + 98;
          let next_game = { ...this.gamesByNumber[current_game.winner_to][0] };
          while(!end) {
            next_game.location_y = location_y + 1;
            next_game.location_x = current_game.location_x + 0.5;
            next_game.location_left = (next_game.location_x - 1) * 196;
            next_game.location_top = (next_game.location_y - 1) * 100 + 50;
            result.games.push(next_game);
            let svg = {};
            svg.left = current_game.location_left;
            svg.top = next_game.location_top - 46;
            svg.width = svg_width;
            svg.height = 62;
            svg.start = {x:svg.width/3+3-5, y:0};
            svg.end = {x:svg.width*2/3-3-5, y:svg.height};
            svg.color = 'green';
            result.svgs.push(svg);
            if (next_game.player1 == '0' || next_game.player2 == '0') {
              location_y++;
              svg_width = 196;
              next_game = { ...this.gamesByNumber[next_game.winner_to][0] };
            } else {
              end = true;
            }
          }
        } else {
          if (current_game.stage == 'F') {
            let second_endpoint = {
              type: 'second',
              location_left: (current_game.location_x - 1.5) * 196,
              location_top: current_game.location_y * 100 + 56
            };
            result.endpoints.push(second_endpoint);
            let first_endpoint = {
              type: 'first',
              location_left: (current_game.location_x - 0.5) * 196,
              location_top: current_game.location_y * 100 + 56
            };
            result.endpoints.push(first_endpoint);
            let second_svg = {};
            second_svg.left = second_endpoint.location_left;
            second_svg.top = current_game.location_y * 100 + 50 - 46;
            second_svg.width = 196 + 98;
            second_svg.height = 62;
            second_svg.start = {x:second_svg.width*2/3-3-5, y:0};
            second_svg.end = {x:second_svg.width/3+1-5, y:second_svg.height};
            second_svg.color = 'red';
            result.svgs.push(second_svg);
            let first_svg = {};
            first_svg.left = current_game.location_left;
            first_svg.top = current_game.location_y * 100 + 50 - 46;
            first_svg.width = 196 + 98;
            first_svg.height = 62;
            first_svg.start = {x:first_svg.width/3+3-5, y:0};
            first_svg.end = {x:first_svg.width*2/3+1-5, y:first_svg.height};
            first_svg.color = 'green';
            result.svgs.push(first_svg);
          }
        }
        if (current_game.loser_to) {
          let end = false;
          let location_y = current_game.location_y;
          let color = 'red';
          let svg_width = 196 + 98;
          let next_game = { ...this.gamesByNumber[current_game.loser_to][0] };
          while(!end) {
            next_game.location_y = location_y + 1;
            next_game.location_x = current_game.location_x - 0.5;
            next_game.location_left = (next_game.location_x - 1) * 196;
            next_game.location_top = (next_game.location_y - 1) * 100 + 50;
            result.games.push(next_game);
            let svg = {};
            svg.left = next_game.location_left;
            svg.top = next_game.location_top - 46;
            svg.width = svg_width;
            svg.height = 62;
            if (svg.width > 196) {
              svg.start = {x:svg.width*2/3-3-5, y:0};
              svg.end = {x:svg.width/3+3-5, y:svg.height};
            } else {
              svg.start = {x:svg.width/2-5, y:0};
              svg.end = {x:svg.width/2-5, y:svg.height};
            }
            svg.color = color;
            result.svgs.push(svg);
            if (next_game.player1 == '0' || next_game.player2 == '0') {
              location_y++;
              color = 'green';
              svg_width = 196;
              next_game = { ...this.gamesByNumber[next_game.winner_to][0] };
            } else {
              end = true;
            }
          }
        } else {
          if (current_game.stage != 'F') {
            let type = 'fail';
            if (current_game.winner_to && this.gamesByNumber[current_game.winner_to][0].stage == 'F') {
              type = 'third';
            }
            let endpoint = {
              type: type,
              location_left: (current_game.location_x - 1.5) * 196,
              location_top: current_game.location_y * 100 + 56
            }
            result.endpoints.push(endpoint);
            let svg = {};
            svg.left = endpoint.location_left;
            svg.top = current_game.location_y * 100 + 50 - 46;
            svg.width = 196 + 98;
            svg.height = 62;
            svg.start = {x:svg.width*2/3-3-5, y:0};
            svg.end = {x:svg.width/3-5, y:svg.height};
            svg.color = 'red';
            result.svgs.push(svg);
          }
        }
      } else {
        let type = 'fail';
        let color = 'red';
        if (current_game.loser == this.search.player_id) {
          if (current_game.stage == 'F') type = 'second';
          else if (current_game.stage == 'P3') type = 'fourth';
        } else {
          color = 'green';
          if (current_game.stage == 'F') type = 'first';
          else if (current_game.stage == 'P3') type = 'third';
        }
        result.endpoints.push({
          type: type,
          location_left: (current_game.location_x - 1) * 196,
          location_top: current_game.location_y * 100 + 50
        });
        let svg = {};
        svg.left = current_game.location_left;
        svg.top = current_game.location_y * 100 + 50 - 46;
        svg.width = 196;
        svg.height = 56;
        svg.start = {x:svg.width/2-5, y:0};
        svg.end = {x:svg.width/2-5, y:svg.height};
        svg.color = color;
        result.svgs.push(svg);
      }
      return result;
    },
    teamMembersByTeamId() {
      if (!this.tournament_team) return null;
      return this.groupArrayBy(this.team_members, 'team_id');
    },
    notifications() {
      let notifications = [];
      if (this.games && this.handicap_relations && this.gameType == 2) {
        let game_tills = [];
        this.games.forEach(game => {
          if (!game_tills.includes(game.game_till)) {
            game_tills.push(game.game_till);
          }
        });
        game_tills.forEach(game_till => {
          if (!this.handicap_relations[game_till]) {
            notifications.push(`В выбранном гандикапе нет распределения фор для игры до ${game_till} шаров!\n Для встреч до ${game_till} шаров фора отображаться не будет.`);
          }
        });
      }
      return notifications;
    }
  },
  methods: {
    getDetails() {
      this.loading = true;
      this.name = '';
      this.info = '';
      axios
      .get('/api/tournament/details', { params: { id: this.$route.params.id } })
      .then(response => {
        this.handicap_id = response.data.handicap_id;
        if (this.handicap_id) {
          this.getHandicap(this.handicap_id);
        }
        this.rating_id = response.data.rating_id;
        this.club_id = response.data.club_id;
        this.fetchedParticipants = response.data.participants;
        this.fetchedGames = response.data.games;
        this.games_previous_tables = {};
        this.fetchedGames.forEach(game => {
          if (game.status == 'running') this.games_previous_tables[game.id] = game.table_number;
        });
        this.owner = response.data.owner;
        this.tournament_id = response.data.id;
        this.tournament_name = response.data.name;
        this.tournament_status = response.data.status;
        this.selectedGame = null;
        this.gameType = response.data.game_type;
        this.wb_till = response.data.wb_till;
        this.lb_till = response.data.lb_till;
        if (response.data.chart_type) {
          this.chart_type = response.data.chart_type;
        } else {
          this.chart_type = 'classic';
        }
        this.num_group_winners = response.data.num_group_winners
        this.num_groups = response.data.num_groups
        if (this.club_id) this.getClub(this.club_id);
        if (this.$route.query.player && this.participants_by_id[this.$route.query.player]) {
          this.search.player_id = this.$route.query.player;
          this.search.player_name = this.participants_by_id[this.$route.query.player][0].name;
        } else {
          this.search.player_id = null;
          this.search.player_name = null;
          this.$router.replace({query: {}});
        }
        this.tournament_team = response.data.team;
        if (this.tournament_team) {
          this.teams = response.data.teams;
          this.team_members = response.data.team_members;
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      })
      .finally(() => this.loading = false)
    },
    createChart() {
      this.chart_type = 'classic';
      if (this.newChart.chart_type == 'classic' && Math.log2(this.newChart.num_players)%1 > 0) {
        this.error = 'Количество участников должно быть степенью 2 (2,4,8,16,32,64 и т.д.)';
        return
      }
      if (this.newChart.chart_type == 'classic' && this.newChart.num_players < this.fetchedParticipants.length) {
        this.error = 'Количество участников не может быть меньше чем зарегистрировано';
        return
      }
      if (this.newChart.chart_type == 'classic' && Math.log2(this.newChart.num_final_players)%1 > 0) {
        this.error = 'Количество участников в олимпийке должно быть степенью 2 (2,4,8,16,32,64 и т.д.)';
        return
      }
      if (this.newChart.chart_type == 'classic' && this.newChart.num_final_players < 2) {
        this.error = 'Количество участников в олимпийке не может быть меньше 2';
        return
      }
      if (this.newChart.chart_type == 'classic' && this.newChart.num_final_players > this.newChart.num_players) {
        this.error = 'Количество участников в олимпийке не может быть больше количества участников';
        return
      }
      if (this.newChart.chart_type == 'seed') {
        for (let i=0; i<this.newChart.seed_rounds.length; i++) {
          if (this.newChart.seed_rounds[i].participants.length > this.newChart.num_final_players/2) {
            this.error = `Количество участников в сеянном раунде не может превышать ${this.newChart.num_final_players/2} при олимпийке на ${this.newChart.num_final_players}`;
            return
          }
        }
      }
      if (this.newChart.chart_type == 'group') {
        if (this.newChart.num_groups < 2) {
          this.error = 'В групповой сетке не может быть меньше 2 групп. Если нужна 1 группы, выберите круговую сетку.';
          return
        }
      }
      if (this.handicap_id) {
        if (!this.tournament_team) {
          for (let i=0; i<this.fetchedParticipants.length; i++) {
            if (!this.fetchedParticipants[i].group) {
              this.error = `Не у всех участников есть группа в гандикапе. ${this.fetchedParticipants[i].name}`;
              return;
            }
          }
        } else {
          for (let i=0; i<this.team_members.length; i++) {
            if (!this.team_members[i].group) {
              this.error = `Не у всех участников есть группа в гандикапе. ${this.team_members[i].full_name}`;
              return;
            }
          }
        }
      }
      this.creatingChart = true;
      this.error = '';
      axios
      .post('/api/tournament/chart/create', {
          tournament_id: this.tournament_id,
          chart_type: this.newChart.chart_type,
          num_players: this.newChart.num_players,
          num_final_players: this.newChart.num_final_players,
          third_place_game: this.newChart.third_place_game,
          lot_by_region: this.newChart.lot_by_region,
          lot_by_rating: this.newChart.lot_by_rating,
          num_groups: this.newChart.num_groups,
          num_group_winners: this.newChart.num_group_winners,
          seed_rounds: this.newChart.seed_rounds
        }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.fetchedGames = response.data.games;
        this.fetchedParticipants = response.data.participants;
        this.chart_type = this.newChart.chart_type;
        this.num_group_winners = this.newChart.num_group_winners;
        this.num_groups = this.newChart.num_groups;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.creatingChart = false)
    },
    deleteChart() {
      if(confirm('Вы уверены что хотите удалить сетку?')) {
        this.deletingChart = true;
        this.error = '';
        if (this.club_tables.length) {
          this.games.forEach(game => {
            if (game.table_number && game.status == 'running') {
              let table = null;
              this.club_tables.forEach(t => {
                if (t.table_number == game.table_number) {
                  table = t;
                  return
                }
              });
              if (!table) {
                this.error = 'table not found';
                return;
              }
              table.status = 'close';
              this.updateTable(table);
            }
          });
        }
        axios
        .post('/api/tournament/chart/delete', {tournament_id: this.tournament_id}, {headers: {'x-access-token': localStorage.token}})
        .then(() => {
          this.fetchedGames = [];
          this.selectedGame = null;
          this.chart_type = null
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.error = error.response.data;
          }
        })
        .finally(() => this.deletingChart = false)
      }
    },
    editChart(third_place_game) {
      this.editingChart = true;
      this.error = '';
      axios
      .post('/api/tournament/chart/edit', {
          tournament_id: this.tournament_id,
          third_place_game: third_place_game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.fetchedGames = response.data.games;
        this.fetchedParticipants = response.data.participants;
        this.tournament_status = response.data.status;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.editingChart = false)
    },
    selectGame(game) {
      if (this.selectedGame == game) {
        this.selectedGame = null;
      } else {
        this.selectedGame = game;
      }
      this.switchingPlayer = null;
      this.switchingStage = null;
    },
    gameTableNumberChange(game) {
      this.error = '';
      game.table_number = game.table_name;
      if (game.status == 'ready') {
        for (let i=0; i<this.games.length; i++) {
          if (this.games[i].id == game.id) continue
          if (this.games[i].status != 'running') continue
          if (game.table_number == this.games[i].table_number) {
            this.error = 'Стол с таким номером уже играет';
            game.table_number = null;
            return;
          }
        }
        return
      }
      this.switchingPlayer = null;
      this.switchingStage = null;
    },
    gameClubTableChange(game) {
      this.error = '';
      let table = null;
      if (!game.table_name) {
        game.table_number = null;
      } else {
        this.club_tables.forEach(t => {
          if (t.table_name == game.table_name) {
            table = t;
            game.table_number = table.table_number;
            return;
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
      }
      if (game.status == 'ready') {
        for (let i=0; i<this.games.length; i++) {
          if (this.games[i].id == game.id) continue
          if (this.games[i].status != 'running') continue
          if (game.table_number == this.games[i].table_number) {
            this.error = 'Стол с таким номером уже играет';
            game.table_number = null;
            return;
          }
        }
        return
      }
      this.switchingPlayer = null;
      this.switchingStage = null;
    },
    saveGame(game) {
      this.loading = true;
      this.error = '';
      axios
      .post('/api/tournament/game/save', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to cancel game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
      this.updateAllTables();
    },
    startGame(game) {
      this.error = '';
      this.loading = true;
      if (game.table_number && this.club_tables.length) {
        let table = null;
        this.club_tables.forEach(t => {
          if (t.table_number == game.table_number) {
            table = t;
            return
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
        table.status = 'open';
        this.updateTable(table);
      }
      axios
      .post('/api/tournament/game/start', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.tournament_status = response.data.status;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to start game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    stopGame(game) {
      this.loading = true;
      this.error = '';
      if (game.table_number && this.club_tables.length) {
        let table = null;
        this.club_tables.forEach(t => {
          if (t.table_number == game.table_number) {
            table = t;
            return
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
        table.status = 'close';
        this.updateTable(table);
      }
      axios
      .post('/api/tournament/game/stop', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.tournament_status = response.data.status;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to stop game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    cancelGame(game) {
      this.error = '';
      this.loading = true;
      if (game.table_number && this.club_tables.length) {
        let table = null;
        this.club_tables.forEach(t => {
          if (t.table_number == game.table_number) {
            table = t;
            return
          }
        });
        if (!table) {
          this.error = 'table not found';
          return;
        }
        table.status = 'close';
        this.updateTable(table);
      }
      game.table_number = null;
      axios
      .post('/api/tournament/game/cancel', {game: game}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.tournament_status = response.data.status;
          this.fetchedParticipants = response.data.participants;
          for (let i=0; i<this.fetchedGames.length; i++) {
            if (this.fetchedGames[i].id == this.selectedGame.id) {
              this.selectedGame = this.fetchedGames[i];
              break;
            }
          }
        } else {
          this.error = 'failed to cancel game'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    isSwitchCapable(participant_id) {
      let result = false;
      for (let i=0; i<this.switchEnabledParticipants.length; i++) {
        let part = this.switchEnabledParticipants[i];
        if (part.id == participant_id) {
          result = true;
          break;
        }
      }
      return result;
    },
    isOlympicSwitchCapable(participant_id) {
      let result = false;
      for (let i=0; i<this.olympicSwitchEnabledParticipants.length; i++) {
        let part = this.olympicSwitchEnabledParticipants[i];
        if (part.id == participant_id) {
          result = true;
          break;
        }
      }
      return result;
    },
    switchParticipantClick(lot, selectedGame) {
      if (this.switchingPlayer == lot) {
        this.switchingPlayer = null;
        this.switchingStage = null;
      } else if (this.chart_type == 'classic' || (this.chart_type == 'group' && selectedGame.stage == 'group') || this.chart_type == 'seed') {
        this.switchingPlayer = lot;
        this.switchingStage = 'initial';
      } else if (this.chart_type == 'group' && selectedGame.stage == 'R1') {
        this.switchingPlayer = lot;
        this.switchingStage = 'olympic';
      }
    },
    switchPlayers(player1_lot,player2_lot) {
      this.error = '';
      this.loading = true;
      let player1_lot_olympic = false;
      if (this.switchingStage == 'olympic') {
        player1_lot_olympic = true;
      }
      axios
      .post('/api/tournament/participant/switch', {player1_lot: parseInt(player1_lot,10), player1_lot_olympic: player1_lot_olympic, player2_lot: parseInt(player2_lot,10), tournament_id: this.tournament_id}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (response.data) {
          this.fetchedGames = response.data.games;
          this.fetchedParticipants = response.data.participants;
        } else {
          this.error = 'failed to switch participants';
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => {
        this.loading = false;
        this.switchingPlayer = null;
        this.switchingStage = null;
        this.selectedGame = null;
      })
    },
    editGames(games) {
      if (!games.length) return;
      this.error = '';
      games.forEach(game => game.updating=true);
      axios
      .post('/api/tournament/games/edit', {games: games}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.fetchedGames = response.data.games;
        this.fetchedParticipants = response.data.participants;
        for (let i = 0; i<this.games.length; i++) {
          if (this.games[i].id == this.selectedGame.id) {
            this.selectGame(this.games[i]);
            break;
          }
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = "Произошла ошибка";
        }
      })
      .finally(() => {
        games.forEach(game => delete game.updating);
      })
    },
    updateTable(table) {
      this.loading = true;
      this.error = null;
      axios
      .post('/api/club/edit/table', { club_id: table.club_id, id: table.id, status: table.status }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        let tables = response.data;
        let table_type = 'pyramid';
        if (this.gameType == '7') table_type = 'snooker';
        if (this.gameType == '8') table_type = 'pool';
        this.club_tables = this.groupArrayBy(tables, 'type')[table_type];
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.loading = false)
    },
    updateAllTables() {
      let tables_by_number = this.groupArrayBy(this.club_tables, 'table_number');
      this.games.forEach(game=>{
        if (game.status != 'running') return;
        //if table number was changed
        if (this.games_previous_tables[game.id] && this.games_previous_tables[game.id] != game.table_number) {
          let previous_table = tables_by_number[this.games_previous_tables[game.id]][0];
          previous_table.status = 'close'
          this.updateTable(previous_table);
          if (game.table_number) {
            this.games_previous_tables[game.id] = game.table_number;
          } else {
            delete this.games_previous_tables[game.id];
          }
        }
        if (!game.table_number) return;
        let table = tables_by_number[game.table_number][0];
        if (table.status == 'close') {
          table.status = 'open';
          this.updateTable(table);
        }
      });
    },
    getHandicap(id) {
      axios
      .get('/api/handicap/get', { params: { id: id } })
      .then(response => {
        this.handicap_relations = response.data[0].relations;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
    },
    getClub(id) {
      axios
      .get('/api/club/get', { params: { id: id } })
      .then(response => {
        this.club = response.data;
        let table_type = 'pyramid';
        if (this.gameType == '7') table_type = 'snooker';
        if (this.gameType == '8') table_type = 'pool';
        this.club_tables = this.groupArrayBy(this.club.tables, 'type')[table_type];
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
        this.loading = false;
      })
    },
    formatDate(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let year = date.getFullYear();
      let result = `${day}.${month}.${year}`;
      return result
    },
    formatTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${hour}:${min}`;
      return result
    },
    formatDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month} ${hour}:${min}`;
      return result
    },
    formatFullDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month}.${year} ${hour}:${min}`;
      return result
    },
    printProtocol() {
      var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      let content = document.getElementById('print-content').innerHTML;
      WinPrint.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <style>
              #header {
                display: flex;
                justify-content: space-between;
              }
              #second-header {
                display: flex;
                justify-content: space-between;
                font-size: 20px;
                margin-top: 20px;
              }
              .frame-cell {
                width: 30px;
              }
              #score-table {
                margin-top: 20px;
                width: 100%;
                border-collapse: collapse;
              }
              tr {
                height: 32px;
              }
              th, td {
                border: 1px solid black;
                padding: 6px;
              }
              #signatures {
                display: flex;
                width: 100%;
                margin-top: 20px;
              }
              .sign {
                width: 50%;
                height: 100px;
                border: 1px solid black;
                padding: 10px;
                margin-right: -1px;
              }
            </style>
          </head>
          <body>
            ${content}
          </body>
        </html>
      `);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.onafterprint = function(){
        WinPrint.close();
      }
    },
    updateGameSchedule(game, value) {
      if (!value.match(/[0-9]{2}\.[0-9]{2}\.[0-9]{4} [0-9]{1,2}:[0-9]{2}/)) return
      try {
        let d = value.split(' ')[0];
        let t = value.split(' ')[1];
        let day = parseInt(d.split('.')[0], 10);
        let month = parseInt(d.split('.')[1], 10);
        let year = parseInt(d.split('.')[2], 10);
        let hours = parseInt(t.split(':')[0], 10);
        let minutes = parseInt(t.split(':')[1], 10);
        let date = new Date(year, month - 1, day, hours, minutes);
        game.estimated_time = date.getTime(); 
        this.editGames([game]);
      } catch(err) {
        console.log(err);
      }
    },
    updateRoundSchedule(location_x, value) {
      if (!value.match(/[0-9]{2}\.[0-9]{2}\.[0-9]{4} [0-9]{1,2}:[0-9]{2}/)) return
      let games_to_update = [];
      this.fetchedGames.forEach(game => {
        if (game.location_x == location_x) {
          let d = value.split(' ')[0];
          let t = value.split(' ')[1];
          let day = parseInt(d.split('.')[0], 10);
          let month = parseInt(d.split('.')[1], 10);
          let year = parseInt(d.split('.')[2], 10);
          let hours = parseInt(t.split(':')[0], 10);
          let minutes = parseInt(t.split(':')[1], 10);
          let date = new Date(year, month - 1, day, hours, minutes);
          game.estimated_time = date.getTime(); 
          games_to_update.push(game);
        }
      });
      this.editGames(games_to_update);
    },
    changeGroupTill(value) {
      if (!value) return
      let games_to_update = [];
      this.fetchedGames.forEach(game => {
        if (game.stage == 'group') {
          game.game_till = value;
          games_to_update.push(game);
        }
      });
      this.editGames(games_to_update);
    },
    changeRoundTill(location_x, value) {
      if (!value) return
      let games_to_update = [];
      this.fetchedGames.forEach(game => {
        if (game.location_x == location_x) {
          game.game_till = value;
          games_to_update.push(game);
        }
      })
      this.editGames(games_to_update);
    },
    changeGameTill(game, value) {
      if (!value) return
      game.game_till = parseInt(value,10);
      this.editGames([game]);
    },
    roundScheduleEnabled(round) {
      for (let i=0; i<this.fetchedGames.length; i++) {
        let game = this.fetchedGames[i];
        if (game.location_x == round && game.player1 != '0' && game.player2 != '0') return true
      }
      return false
    },
    chartToggle(toggle) {
      if (toggle == 'scheduling') {
        this.till_setup = false;
      } else if (toggle == 'till_setup') {
        this.scheduling = false;
      }
    },
    groupTillPlus() {
      this.changeGroupTill(this.groups_till+1);
    },
    groupTillMinus() {
      this.changeGroupTill(this.groups_till-1);
    },
    roundTillPlus(round) {
      this.changeRoundTill(round, this.roundsTill[round] + 1);
    },
    roundTillMinus(round) {
      this.changeRoundTill(round, this.roundsTill[round] - 1);
    },
    gameTillPlus(game) {
      this.changeGameTill(game, game.game_till + 1)
    },
    gameTillMinus(game) {
      this.changeGameTill(game, game.game_till - 1)
    },
    gameNumberMouseover(game) {
      let description = `winner to ${game.game_number}`;
      if (game.stage.startsWith('LB')) description = `loser to ${game.game_number}`;
      for (let i=0; i<this.games.length; i++) {
        if (this.games[i].description == description) {
          if (game.stage.startsWith('LB')) {
            window.document.getElementById(`${this.games[i].game_number}-game`).classList.add('highlight-loser');
          } else {
            window.document.getElementById(`${this.games[i].game_number}-game`).classList.add('highlight-winner');
          }
          break;
        }
      }
    },
    gameNumberMouseout(game) {
      let description = `winner to ${game.game_number}`;
      if (game.stage.startsWith('LB')) description = `loser to ${game.game_number}`;
      for (let i=0; i<this.games.length; i++) {
        if (this.games[i].description == description) {
          if (game.stage.startsWith('LB')) {
            window.document.getElementById(`${this.games[i].game_number}-game`).classList.remove('highlight-loser');
          } else {
            window.document.getElementById(`${this.games[i].game_number}-game`).classList.remove('highlight-winner');
          }
          break;
        }
      }
    },
    gameNumberClick(game) {
      let description = `winner to ${game.game_number}`;
      if (game.stage.startsWith('LB')) description = `loser to ${game.game_number}`;
      for (let i=0; i<this.games.length; i++) {
        if (this.games[i].description == description) {
          let target_game = window.document.getElementById(`${this.games[i].game_number}-game`);
          if (game.stage.startsWith('LB')) {
            target_game.classList.add('clicked-loser');
            setTimeout(() => {
              target_game.classList.remove('clicked-loser');
            }, 5000);
          } else {
            target_game.classList.add('clicked-winner');
            setTimeout(() => {
              target_game.classList.remove('clicked-winner');
            }, 5000);
          }
          target_game.scrollIntoView();
          break;
        }
      }
    },
    descriptionMouseover(game) {
      if (game.description.includes('winner')) {
        window.document.getElementById(`${game.winner_to}-game`).classList.add('highlight-winner');
      } else if (game.description.includes('loser')) {
        window.document.getElementById(`${game.loser_to}-game`).classList.add('highlight-loser');
      }
    },
    descriptionMouseout(game) {
      if (game.description.includes('winner')) {
        window.document.getElementById(`${game.winner_to}-game`).classList.remove('highlight-winner');
      } else if (game.description.includes('loser')) {
        window.document.getElementById(`${game.loser_to}-game`).classList.remove('highlight-loser');
      }
    },
    descriptionClick(game) {
      if (game.description.includes('winner')) {
        let target_game = window.document.getElementById(`${game.winner_to}-game`);
        target_game.classList.add('clicked-winner');
        target_game.scrollIntoView();
        setTimeout(() => {
          target_game.classList.remove('clicked-winner');
        }, 5000);
      } else if (game.description.includes('loser')) {
        let target_game = window.document.getElementById(`${game.loser_to}-game`);
        target_game.classList.add('clicked-loser');
        target_game.scrollIntoView();
        setTimeout(() => {
          target_game.classList.remove('clicked-loser');
        }, 5000);
      }
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    getNextGames(game) {
      let roundGames = this.gamesByLocationX[game.location_x];
      let next_games = [];
      roundGames.forEach(g => {
        if (g.description && g.description.startsWith('winner to')) {
          next_games.push(g.winner_to);
        } else if (g.description && g.description.startsWith('loser to')) {
          next_games.push(g.loser_to);
        }
      });
      return next_games;
    },
    nextGameChange(game) {
      let roundGames = this.gamesByLocationX[game.location_x];
      let next;
      if (game.description.startsWith('winner to')) {
        next = 'winner_to';
      } else if (game.description.startsWith('loser to')) {
        next = 'loser_to';
      } else {
        return
      }
      for (let i=0; i<roundGames.length; i++) {
        if (roundGames[i].game_number == game.game_number || !roundGames[i].description || (!roundGames[i].description.startsWith('winner to') && !roundGames[i].description.startsWith('loser to'))) continue;
        if (roundGames[i][next] == game[next]) {
          roundGames[i][next] = this.old_next_game;
          this.old_next_game = game[next];
          this.editGames([game,roundGames[i]]);
          break;
        } 
      }
    },
    addYoutube(game) {
      let url = prompt('Ссылка на трасляцию', '');
      if (url != null) {
        game.youtube_link = url;
        this.updateGameVideo(game);
      }
    },
    updateGameVideo(game) {
      this.error = '';
      if (!game.youtube_link) game.youtube_link = null;
      axios
      .post('/api/tournament/add/video', { game_id: game.id, url: game.youtube_link }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        if (!response.data) {
          game.youtube_link = null;
          this.error = 'Не получилось добавить трансляцию'
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
    },
    convertOlympicLot(lot) {
      if (!lot) return null;
      if (lot > this.num_group_winners * this.num_groups) return 'X';
      let mod = lot%this.num_group_winners;
      if (!mod) mod = this.num_group_winners;
      return String.fromCharCode(Math.ceil(lot/this.num_group_winners) + 64) + mod.toString();
    },
    chartTypeChange() {
      if (this.newChart.chart_type == 'seed' && !this.newChart.seed_rounds.length) {
        let seed_count = 2;
        if (this.fetchedParticipants.length > 8) seed_count = 8;
        let seed_round = {
          count: seed_count,
          count_options: [],
          top: true,
          no_delete: true,
          participants: []
        }
        if (this.rating_id) {
          let rating_sorted_participants = [...this.fetchedParticipants].sort((a, b) => b.rating_points - a.rating_points);
          for (let i=0; i<seed_round.count; i++) {
            seed_round.participants.push(rating_sorted_participants[i]);
          }
        } else {
          for (let i=0; i<seed_round.count; i++) {
            seed_round.participants.push({});
          }
        }
        let i=1;
        let option_value = Math.pow(2,i);
        while (option_value < this.fetchedParticipants.length) {
          seed_round.count_options.push({
            value: Math.pow(2, i),
            title: Math.pow(2, i)
          });
          i++;
          option_value = Math.pow(2,i);
        }
        this.newChart.seed_rounds.push(seed_round);
      }
    },
    seedRoundCountChange(round, index) {
      this.error = '';
      round.participants = [];
      let seedParticipantsBefore = 0;
      if (index > 0) {
        for (let i=0; i<index; i++) {
          seedParticipantsBefore += this.newChart.seed_rounds[i].participants.length;
        }
      }
      if (this.rating_id) {
        let rating_sorted_participants = [...this.fetchedParticipants].sort((a, b) => b.rating_points - a.rating_points);
        for (let i=seedParticipantsBefore; i<seedParticipantsBefore + round.count; i++) {
          round.participants.push(rating_sorted_participants[i]);
        }
      } else {
        for (let i=seedParticipantsBefore; i<seedParticipantsBefore + round.count; i++) {
          round.participants.push({});
        }
      }
      if (index + 1 < this.newChart.seed_rounds.length) {
        let next_round = this.newChart.seed_rounds[index + 1];
        if (this.fetchedParticipants.length - seedParticipantsBefore - round.count < round.count) {
          this.deleteSeedRound(index+1);
        } else if (next_round.count < round.count) {
          next_round.count = round.count;
          this.seedRoundCountChange(next_round, index+1);
        }
      }
    },
    addSeedRound() {
      this.error = '';
      let seedParticipantsCount = 0;
      let previous_round = this.newChart.seed_rounds[this.newChart.seed_rounds.length - 1];
      this.newChart.seed_rounds.forEach(round => {
        seedParticipantsCount += round.participants.length;
      });
      if (this.fetchedParticipants.length - seedParticipantsCount < previous_round.participants.length) {
        this.error = 'Слишком мало участников для еще одного сеянного раунда.'
        return;
      }
      let seed_round = {
        count: previous_round.participants.length,
        count_options: [],
        top: false,
        no_delete: false,
        participants: []
      }
      if (this.rating_id) {
        let rating_sorted_participants = [...this.fetchedParticipants].sort((a, b) => b.rating_points - a.rating_points);
        for (let i=seedParticipantsCount; i<seedParticipantsCount + seed_round.count; i++) {
          seed_round.participants.push(rating_sorted_participants[i]);
        }
      } else {
        for (let i=seedParticipantsCount; i<seedParticipantsCount + seed_round.count; i++) {
          seed_round.participants.push({});
        }
      }
      let i = Math.log2(previous_round.count);
      let option_value = previous_round.count;
      while (option_value < this.fetchedParticipants.length - seedParticipantsCount) {
        seed_round.count_options.push({
          value: Math.pow(2, i),
          title: `${seedParticipantsCount + 1}-${seedParticipantsCount + Math.pow(2, i)}`
        });
        i++;
        option_value = Math.pow(2,i);
      }
      this.newChart.seed_rounds.push(seed_round);
    },
    deleteSeedRound(index) {
      this.newChart.seed_rounds.splice(index, 1);
    },
    saveTable(table) {
      table.loading = true;
      this.error = null;
      axios
      .post('/api/club/edit/table', { club_id: this.club.id, id: table.id, table_name: table.table_name, status: table.status }, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.tables = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => table.loading = false)
    },
    teamHandicapGroup(team_id) {
      if (!team_id) return
      if (!this.handicap_id) return
      let group_sum = 0;
      if (!this.teamMembersByTeamId[team_id] || !this.teamMembersByTeamId[team_id].length) {
        return null;
      }
      this.teamMembersByTeamId[team_id].forEach(member => {
        if (member.group) {
          group_sum += member.group;
        }
      })
      // return Math.round(group_sum / this.teamMembersByTeamId[team_id].length);
      return group_sum
    },
    changeTournamentStatus(status) {
      this.changing_status = true;
      if (status == 'pending') {
        for (let i=0; i<this.games.length; i++) {
          if (this.games[i].status == 'finished' && this.games[i].player1 && this.games[i].player1 != "0" && this.games[i].player2 && this.games[i].player2 != "0") {
            status = 'running';
            break;
          }
        }
      }
      this.error = '';
      axios
      .post('/api/tournament/status/edit', {id: this.tournament_id, status: status}, {headers: {'x-access-token': localStorage.token}})
      .then(() => this.tournament_status = status)
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.changing_status = false)
    },
  },
  created() {
    this.getDetails();
    this.username = localStorage.username;
    this.role = localStorage.role;
  },
}
</script>

<style scoped>
#content {
  height: 100%;
}
#chart-container, #game-schedule-container, #game-till-container, #group-chart-container, #round-chart-container {
  flex-wrap: wrap;
  position: relative;
  overflow: auto;
  height: 100%;
  width: 100%;
  margin: 0;
}
#game-till-container, #game-schedule-container {
  margin-top: 10px;
}
#schedule-container, #till-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
}
.actions {
  margin-bottom: 5px;
  padding: 0px 20px !important;
}
.game-table {
  position: absolute;
  display: flex;
  flex-direction: column;
  font-size: x-small;
  padding-right: 20px;
  -youbkit-touch-callout: none; /* iOS Safari */
  -youbkit-user-select: none;   /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;        /* IE 10+ and Edge */
  user-select: none;   
  justify-content: flex-end;
}
.game-header {
  position: relative;
  margin-left: 21px;
  display: flex;
  justify-content: space-between;
}
.game-number {
  display: flex;
  justify-content: center;
  background-color: rgb(47 129 48);
  color: #ffffff;
  padding: 2px;
  width: 15px;
  height: 12px;
  padding: 2px;
  align-self: flex-end;
}
.game-number.with-youtube {
  margin-left: none;
}
.table-number {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 2px;
  color: #005199;
  z-index: 2;
  background: white;
}
.game-time {
  display: flex;
  align-items: center;
}
.game-table-line {
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-bottom: -1px;
}
.line-connector {
  display: flex;
  flex-direction: row;
}
.game-table-player-lot {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 15px;
  padding: 3px;
}
.game-table-name-cell {
  border: 1px solid #ddd;
  width: 110px;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 3px;
}
.game-table-name-cell.running {
  background-color: #faecd3;
}
.game-table-name-cell.ready {
  background-color: #e3fcde;
}
.game-table-score-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  width: 15px;
  margin-left: -1px;
}
.game-table-score-cell.running {
  background-color: #faecd3;
}
.game-table-score-cell.ready {
  background-color: #e3fcde;
}
.game-table-score-cell i {
  color: #005199;
}
.game-table-handicap {
  color: #0018ff;
  display: flex;
  align-items: center;
  margin: 3px;
}
.game-description {
  text-align: right;
  margin-top: 2px;
  font-style: italic;
  z-index: 4;
  color: #000057;
}
.game-description select {
  margin: 0;
  font-size: 9px;
  padding: 2px;
}
.schedule, .till {
  border: 1px solid #ccc;
  margin-left: 21px;
  width: 132px;
  height: 35px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.schedule input, .till input {
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  padding: 0;
}
.schedule label, .till label {
  width: 100%;
  text-align: center;
}
.till input {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 15px;
}
.till i {
  margin: 5px;
}
.till i:hover {
  color: #248d24;
}
#round-schedule-container, #round-till-container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}
#round-schedule-container .schedule, #round-till-container .till {
  margin-right: 35px;
}
.round-schedule {
  position: absolute;
  top: 0px;
}
.round-schedule i, .till-schedule i {
  align-self: center;
  margin-left: 21px;
  text-align: center;
}
.round-schedule, .till-schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.round-schedule.group {
  position: unset;
  top: auto;
  align-self: flex-start;
}
.chart-container-with-admin, .personal-chart-container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}
#game-settings {
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 100%;
  background: rgb(236 244 236);
  overflow-y: auto;
  border-radius: 15px;
  transition: transform .2s ease-in;
}
#game-settings select,
#game-settings-mobile select {
  margin: 2px;
  border: none;
  outline: none;
}
#game-settings .actions, #game-settings-mobile .actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#game-settings button:not(.action), #game-settings-mobile button:not(.action){
  margin: 10px;
  border: none;
  background: inherit;
  color: black;
  font-size: 20px;
  align-self: center;
}
#game-settings .game-table-line button:not(.action) {
  margin:0;
}
#game-settings button:not(.action):hover {
  color: #248d24;
  background: inherit;
}
#game-settings button:not(.action).active {
  color: #248d24;
  background: inherit;
}
#game-settings .game-controls, #game-settings-mobile .game-controls {
  margin-left: auto;
} 
#game-settings a, #game-settings-mobile a {
  font-size: 15px;
  text-decoration: none;
  color: #445142;
  padding: 3px;
  margin: 5px;
  margin-top: 0;
  margin-bottom: 0;
  background: inherit;
  border-radius: 5px;
}
#game-settings a:hover, #game-settings-mobile a:hover {
  color: #248d24;
}
a.play-button {
  color: #248d24 !important;
}
a.play-button:hover {
  color: #84dc84 !important;
}
a.stop-button, a.cancel-button {
  color: #e10808 !important;
}
a.stop-button:hover, a.cancel-button:hover {
  color: #e77a7a !important;
}
#game-settings-table {
  display: flex;
  justify-content: center;
  margin: 15px;
}
#game-settings-mobile #game-settings-table {
  margin: 5px;
}
#game-settings-table .game-table {
  position: unset;
  padding: 0;
}
#game-settings-table .game-table label { 
  text-align: center;
  font-size: 12px;
  margin-top: 3px;
}
#game-settings-table .table-number input, #game-settings-table .table-number span {
  font-size: 12px;
}
#game-settings-table .table-number input {
  width: 20px;
  text-align: center;
  height: 16px;
  border: none;
  padding: 0;
}
#game-settings-table .game-table-line {
  height: 40px;
  font-size: inherit;
}
#game-settings-table .game-table-name-cell {
  font-size: 15px;
  width: 130px;
}
#game-settings-mobile .game-table-name-cell {
  font-size: 12px;
}
#game-settings-table .game-table-score-cell {
  width: 30px;
}
#game-settings-table .game-table-score-cell input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  font-size: 15px;
  text-align: center;
  outline: none;
}
#game-settings-table input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
#game-settings-table input[type=number] {
  -moz-appearance: textfield;
}
#game-settings-switch-list li {
  padding: 10px;
}
#game-settings-mobile {
  display: flex;
  position: relative;
  bottom: 0;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 220px;
  background: rgb(236 244 236);
  overflow-y: auto;
  border-radius: 15px;
  z-index: 2;
  font-size: 12px;
  transition: transform .2s ease-in;
}
#game-settings-mobile #game-settings-header {
  display: flex;
}
.slide-left-enter-from, .slide-left-leave-to{
  transform: translateX(200px);
}
.slide-top-enter-from, .slide-top-leave-to{
  transform: translateY(200px);
}
.game-footer {
  display: flex;
  justify-content: center;
}
.game-settings-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
}
button.expander {
  border: none;
  border-radius: 0;
  background: black;
  color: white;
  width: 100%;
  margin: 0;
  margin-top: 15px;
}
button.expander:hover {
  background: black;
  color:#248d24;
}
.walk-over {
  justify-content: center;
}
svg {
  position: absolute;
}
#create-chart-div {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}
#create-chart-div input,
#create-chart-div select {
  margin: 10px;
  font-size: 13px;
  padding: 2px;
}
#create-chart-div label {
  margin-left: 10px;
  color: rgb(103, 103, 103);
  font-size: 15px;
}
#save-button {
  margin-left: auto;
}
#delete-chart-button {
  margin-right: 20px;
  margin-left: auto;
  color: #e10808;
}
#delete-chart-button:hover {
  color: #e77a7a;
}
.toggle-with-label {
  display: flex;
  align-items: center;
}
.toggle-with-label span {
  margin: 0 5px;
  font-size: 10px;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: #445142;
}
.highlight-winner, .clicked-winner {
  box-shadow: 0 0 5px green;
  border-radius: 8px;
  background: #f9f8fc;
}
.highlight-loser, .clicked-loser {
  box-shadow: 0 0 5px #9b0606;
  border-radius: 8px;
  background: #f9f8fc;
}
.youtube-link {
  position: absolute;
  width: 21px;
  margin-left: -21px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.flag {
  margin: 0px -17px 0px -20px !important;
}
h4 {
  color: rgb(186 72 8);
  align-self: flex-start;
  font-size: 13px;
}
i.expander {
  margin-left: 20px;
}
#group-container {
  display: flex;
  flex-wrap: wrap;
}
.group-games-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  flex-direction: column;
  margin: 5px;
  overflow: visible;
}
.group-games-container-header {
  border-bottom: 1px solid #ccc;
  align-self: stretch;
  padding: 10px;
  background: #f2f2f2;
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.group-games-container-content {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding: 10px;
}
.group-participants, .group-chart {
  font-size: 13px;
  width: auto;
  margin-bottom: 10px;
}
.group-chart td {
  background-color: #ffffff;
}
.group-chart .score-cell {
  text-align: center;
}
.group-chart .score-cell.running {
  background-color: #faecd3;
}
.group-chart .score-cell.ready {
  background-color: #e3fcde;
}
.group-chart .score-cell.X {
  background-color: #e0e0e0;
}
.group-games {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
  justify-content: center;
}
.group-games .game-table {
  position: unset;
  margin: 5px;
}
.seed-rounds .add-button {
  margin-left: 10px;
  font-size: 20px;
}
.seed-round-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  display: flex;
  flex-direction: column;
  margin: 10px;
  overflow: visible;
}
.seed-round-header {
  display: flex;
  border-bottom: 1px solid #ccc;
  align-self: stretch;
  align-items: center;
  padding: 10px;
  background: #f2f2f2;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}
.seed-round-header span, .seed-round-header select.seed-round-count {
  color: #0e7c0d;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  background: #f2f2f2;
  border: none;
  font-size: 14px !important;
  outline: none;
  margin: 0 !important;
}
.seed-round-header .delete-button {
  margin-left: auto;
  color: #e10808;
}
.seed-round-header .delete-button:hover {
  color: #e77a7a;
}
.participant-cell {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-bottom: 1px solid #ccc;
}
.participant-cell.last {
  border-bottom: none;
}
.participant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#tables-container {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 10;
  background: white;
  max-width: 500px;
  min-width: 200px;
  border-radius: 10px;
  box-shadow: 0 0 5px #ccc;
  max-height: 32px;
  display: flex;
  flex-direction: column;
}
#tables-container.expanded {
  max-height: 100%;
  transition: max-height 0.4s ease-in;
}
#tables-container .expand-button {
  display: flex;
  justify-content: space-between;
  color: black;
  background: #ededed;
  width: 200px;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0 0;
  margin: 0;
}
#tables-container .expand-button.closed {
  border-radius: 10px;
}
#tables-container .expand-button:hover {
  box-shadow: 0 0 10px green;
}
.tables-list {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-top: 0;
  border-radius: 0 0 10px 10px;
  overflow: scroll;
}
/* .table-expand-enter-from, .table-expand-leave-to{
  max-height: 0px;
  opacity: 0;
}
.table-expand-enter-active,
.table-expand-leave-active {
  transition: all 1s;
  max-height: 1000px;
} */
.table {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
}
.table.last {
  border-bottom: none;
}
.cell {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.cell > div {
  display: flex;
  align-items: center;
}
.cell select {
  margin: 5px;
  border-radius: 5px;
}
.cell label {
  color: rgb(109, 109, 109);
  margin: 5px;
}
.cell span {
  margin: 5px;
}
.chart-search-div {
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px;
  align-self: flex-start;
  width: 300px;
}
.chart-search-div.mobile {
  width: auto;
  align-self: stretch;
}
.chart-search-div.expanded {
  border-radius: 10px 10px 0 0;
}
.chart-search-div > i {
  font-size: 13px;
  margin: 5px;
}
.chart-search-div > input {
  outline: none;
  border: none;
  flex: 1;
  font-size: 13px;

}
.chart-search-div > label {
  font-size: 13px;
  margin-left: auto;
  font-weight: bold;
}
.chart-search-div > button {
  font-size: 13px;
  padding: 5px;
  border: none;
  margin: 0px;
  margin-left: auto;
  font-weight: bold;
  color: #5f5d5d;
  background-color: inherit;
}
.chart-search-div > button:hover {
  background-color: inherit;
  color: #9b0606;
}
.chart-search-div .players-list {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 200;
  background: whitesmoke;
  border: 1px solid #ccc;
  border-radius: 0 0 10px 10px;
  overflow-y: auto;
  max-height: 400px;
  top: 33px;
  left: -1px;
  width: 100%;
  transition: height .4s ease-in-out;
}
.chart-search-div .players-list a {
  padding: 10px;
  font-size: 13px;
}
.chart-search-div .players-list a:hover {
  background-color: white;
}

.expand-enter-from, .expand-leave-to{
  transform: scaleY(0);
}
.personal-chart-container .endpoint {
  position: absolute;
  width: 186px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
}
.personal-chart-container .endpoint.fail {
  color: red;
}
.personal-chart-container .endpoint.first {
  color: #c38908;
}
.personal-chart-container .endpoint.second {
  color: #8d8d8d;
}
.personal-chart-container .endpoint.third {
  color: #cb5800;
}
.personal-chart-container .endpoint.fourth {
  color: #122d12;
}
.personal-game {
  position: absolute;
  display: flex;
}
.personal-game .stage {
  color: #737373;
  font-size: 14px;
  margin-top: 26px;
  width: 30px;
  text-align: right;
  margin-right: 1px;
}
.personal-game .game-table {
  position: unset;
}
.personal-game .game-header {
  margin-left: 0;
}
.gear_setting_container {
  margin-left: auto;
}
.gear_settings_expand {
  width: 200px;
}
</style>  